<template>
  <span
    v-if="show"
    :id="id"
    class="cursor-pointer"
  >
    <svg
      v-if="!valid"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="8"
        fill="#EB5757"
      />
      <path
        d="M8.90051 3.57617L8.70307 9.53631H7.24697L7.0372 3.57617H8.90051ZM8.01204 12.4238C7.69943 12.4238 7.4403 12.3292 7.23463 12.14C7.0372 11.9426 6.93848 11.6999 6.93848 11.412C6.93848 11.124 7.0372 10.8855 7.23463 10.6963C7.4403 10.4988 7.69943 10.4001 8.01204 10.4001C8.31642 10.4001 8.56733 10.4988 8.76477 10.6963C8.96221 10.8855 9.06093 11.124 9.06093 11.412C9.06093 11.6999 8.96221 11.9426 8.76477 12.14C8.56733 12.3292 8.31642 12.4238 8.01204 12.4238Z"
        fill="white"
      />
    </svg>

    <svg
      v-if="valid"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="8"
        fill="#31D0AA"
      />
      <path
        d="M5 8.15L7 10.25L11 5.75"
        stroke="white"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>
<script>
export default {
  name: 'ValidationIcon',
  props: {
    id: {
      type: String,
      default: 'validation-icon-id',
    },
    show: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="isLoaded"
      :class="!isPopup ? 'ml-1':''"
    >
      <div
        class="onboard-checklist box-shadow-7"
        :class="isPopup ? 'checklist-popup-width':''"
      >
        <b-row class="mr-0">
          <b-col
            class="pr-0 checklist col-12"
          >
            <div
              v-if="!isPopup"
              class="dashboard-title py-1 pl-1"
            >
              Onboarding checklist
            </div>
            <div
              v-if="isPopup"
              class="popup-content"
            >
              <div class="d-flex direction-column position-relative ml-1 w-100">
                <div>
                  <div class="w-100 popup-heading">
                    Onboarding Checklist
                  </div>
                  <div class="font-weight-bold">
                    Let's start with basics
                  </div>
                </div>
                <div class="contact d-flex justify-content-center h-100 align-items-center mr-1">
                  <feather-icon
                    icon="ChevronDownIcon"
                    class="float-right cursor-pointer"
                    size="22"
                    @click="closePopup"
                  />
                </div>
              </div>
            </div>
            <div class="px-1 data my-1">
              <div class="progress-bar-border mx-8-px">
                <b-progress-bar
                  max="100"
                  class="progress-bar-checklist"
                  :value="percentage"
                />
                <span class="progress-dot" />
              </div>
              <div>
                <on-boarding-checklist-timeline
                  v-for="checkLists in checkList"
                  :key="checkLists.title"
                  :title="checkLists.title"
                  :hovermsg="checkLists.hovermsg"
                  :visible="checkLists.visible"
                  :link="checkLists.link"
                  :next-data="checkLists.nextData"
                  :is-popup="isPopup"
                  :tab-index="checkLists.tabIndex"
                />
              </div>
              <div class="checklist-content mt-1">
                We're excited to help grow your business. If you have any other questions, you can connect with your account manager through the <b-link
                  class="link contact-link"
                  :href="HELP_URL"
                > Help section </b-link>  anytime.
              </div>
              <b-button
                class="mt-1"
                variant="custom-secondary"
                @click="startTour"
              >
                Run tour
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-card
      v-if="!isLoaded && !isPopup"
      class="text-center py-4"
    >
      <b-spinner />
    </b-card>
  </div>
</template>

<script>
import OnBoardingChecklistTimeline from '@/@core/components/app-timeline/onBoardingChecklistTimeline.vue'
import { apiToastWarning } from '@/@core/utils/toast'
import constants, {
HELP_URL,
TOOLKIT_PAGE,
checkListTitle,
tooltipData,
} from '@/constants'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'
import {
BButton,
BCard,
BCol,
BLink,
BProgressBar,
BRow,
BSpinner,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'

export default {
  components: {
    BRow,
    BCol,
    BProgressBar,
    BLink,
    BCard,
    OnBoardingChecklistTimeline,
    BSpinner,
    BButton,
  },
  mixins: [UserRoleMixinVue],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkList: null,
      checkListData: null,
      bellIcon: require('@/assets/images/icons/bellIcon.svg'),
      percentage: 0,
      isLoaded: false,
      TOOLKIT_PAGE,
      HELP_URL: HELP_URL(),
    }
  },
  mounted() {
    this.getCheckListData()
    this.$root.$refs.onboardCheckList = this
  },
  methods: {
    getCheckListData() {
      this.$kpRequest({ ...this.$kpEndpoint.profile.onBoardCheckList })
        .then(res => {
          this.checkListData = res.data.data
          this.assignData()
        })
        .catch(() => {
          apiToastWarning('Checklist is not fetched')
        })
    },
    closePopup() {
      if (this.isPopup) {
        this.$root.$refs.checklistPopup._data.showPopup = false
      }
    },
    startTour() {
      if (this.$route.name === 'profile') {
        this.$router.push('/dashboard')
      }
      this.$root.$refs.appTour.startTour(TOOLKIT_PAGE.WELCOME_TO_KINGPIN)
      this.closePopup()
      const profile = store.getters.profile
      analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_START_TOUR_ON_DASHBOARD, profile)
    },
    assignData() {
      this.percentage = this.checkListData.percentage
      this.checkList = [{
        title: checkListTitle.general,
        hovermsg: this.isRetailer ? tooltipData.retailer.general : tooltipData.brand.general,
        visible: this.checkListData.general || false,
        link: 'profile',
        nextData: this.checkListData.segmentation,
        tabIndex: 0,
      },
      {
        title: checkListTitle.segmentation,
        hovermsg: this.isRetailer ? tooltipData.retailer.segmentation : tooltipData.brand.segmentation,
        visible: this.checkListData.segmentation || false,
        link: 'profile',
        nextData: this.checkListData.documents,
        tabIndex: 2,
      },
      {
        title: checkListTitle.documents,
        hovermsg: this.isRetailer ? tooltipData.retailer.documents : tooltipData.brand.documents,
        visible: this.checkListData.documents || false,
        link: 'profile',
        nextData: this.checkListData.address,
        tabIndex: 3,
      },
      {
        title: checkListTitle.addresses,
        hovermsg: this.isRetailer ? tooltipData.retailer.addresses : tooltipData.brand.addresses,
        visible: this.checkListData.address || false,
        link: 'profile',
        nextData: this.isRetailer ? this.checkListData.firstOrder : this.checkListData.firstCollection,
        tabIndex: 4,
      }]

      if (!this.isRetailer) {
        this.checkList.push({
          title: checkListTitle.firstCollection,
          hovermsg: tooltipData.brand.firstCollection,
          visible: this.checkListData.firstCollection || false,
          link: 'collection/edit',
          nextData: this.checkListData.firstOrder,
        })
      }

      this.checkList.push({
        title: this.isRetailer ? checkListTitle.retailerFirstOrder : checkListTitle.brandFirstOrder,
        hovermsg: this.isRetailer ? tooltipData.retailer.firstorder : tooltipData.brand.firstorder,
        visible: this.checkListData.firstOrder || false,
        link: this.isRetailer ? 'explore' : 'orders',
        nextData: this.checkListData.teammates,
      },
      {
        title: checkListTitle.teammates,
        hovermsg: tooltipData.retailer.teammates,
        visible: this.checkListData.teammates || false,
        link: 'profile',
        nextData: this.checkListData.referrals,
        tabIndex: 5,
      })
      // {
      //   title: this.isRetailer ? checkListTitle.retailerReferrals : checkListTitle.brandReferrals,
      //   hovermsg: tooltipData.brand.referrals,
      //   visible: this.checkListData.referrals || false,
      //   link: 'referrals',
      // }

      this.isLoaded = true
    },
  },
}
</script>

<style lang='scss' >
@import "~@core/scss/base/pages/dashboard-ecommerce.scss";
</style>

import { kpRequest, kpEndpoint } from '@/axios'

export const GET_SALES_ANALYTICS_SELECTIONS_LIST = 'getSalesAnalyticsSelectionsList'

export const actions = {
    async [GET_SALES_ANALYTICS_SELECTIONS_LIST](ctx, params = {}) {
        return await kpRequest({ ...kpEndpoint.integrations.salesAnalytics, params })
    },
}

export default {
    actions,
}

import store from '@/store'
import { getEntityType } from '@/store/jwt.service'
import { FETCH_USER_PROFILE } from '@/store/modules/auth.module'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import ability from './ability'

const { BRAND_ACCESS, ROLES } = constants

export const canNavigate = async to => {
  const role = getEntityType()
  const marketPlaceAccessRoutes = ['explore', 'discover-products', 'premiumbrands']
  // Checking retailer having access to these pages
  if (role === ROLES.RETAILER && marketPlaceAccessRoutes.includes(to.name)) {
    const profile = await store.dispatch(FETCH_USER_PROFILE)
    if (!profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)) {
      return false
    }
  }
  // In case of role is guest check for is there is guestResource for navigating

  if (role === ROLES.GUEST) {
    return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.guestResource || route.meta.resource))
  }
  return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
}

export const _ = undefined

<template>
  <div>
    <div
      v-if="showPopup"
      class="popup-onboard"
    >
      <on-boarding-checklist
        :is-popup="true"
      />
    </div>
    <b-button
      class="getting-started"
      variant="none"
      :class="showPopup ? 'opened':''"
      @click="togglePopup()"
    >
      <span> Get started </span>
      <span class="line1" />
      <span class="line2" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import OnBoardingChecklist from './OnBoardingChecklist.vue'

export default {
  components: {
    OnBoardingChecklist,
    BButton,
  },
  data() {
    return {
      showPopup: false,
    }
  },
  mounted() {
    this.$root.$refs.checklistPopup = this
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
  .getting-started{
    position: fixed;
    left: 110px;
    bottom: 32px;
    background: $dark-blue;
    color: $white !important;
    border-radius: 40px;
    transition: all linear .3s;
    font-size: 16px;
    font-weight: 700;
    z-index: 15;
    height: 44px;
  }
  .line1, .line2{
    display: block;
    width: 30px;
    background: $white;
    opacity: 0;
    line-height: 0;
    transition: all linear .3s;
}

.getting-started.opened{
  padding-left: 0;
  padding-right: 0;
}
.getting-started.opened {
  span {
    display: none;
  }
}
.getting-started.opened {
    span.line1, span.line2{
    display: block;
    margin: 7px;
    height: 3px;
    opacity: 1;
  }
}
.getting-started.opened {
  span.line1{
    transform: rotate(45deg) translate(2px, 3px) scale(.8);
  }
}
.getting-started.opened {
  span.line2{
    transform: rotate(128deg) translate(-5px, 5px) scale(0.8);
  }
}
.popup-onboard{
  position: fixed;
  bottom: 85px;
  left: 122px;
  animation-duration: .3s;
  transform: scale(1);
  animation-name: slidein;
  z-index: 15;
}
@keyframes slidein {
  from {
    bottom: 0px;
    left: 0px;
    transform: scale(.4) translate(-122px, 266px);
  }

  to {
    bottom: 85px;
    left: 122px;
    transform: scale(1);
  }
}
@media (max-width: 1200px) {
    .getting-started{
        margin-left: 1rem;
        left: 15px;
    }
    .popup-onboard{
        left: 30px;
        margin-left: 1rem;
    }
    @keyframes slidein {
      from {
        bottom: 0px;
        left: 0px;
        transform: scale(.4) translate(-120px, 266px);
      }
      to {
        bottom: 85px;
        left: 30px;
        transform: scale(1);
      }
    }
}
</style>

export const SET_SHOULD_DISPLAY_TOOLKIT_POPUP = 'setShouldDisplayToolkitPopup'
export const SET_IS_TOOLKIT_FOR_WALLET_REFERRALS = 'setIsToolkitForWalletReferrals'

const state = {
    shouldDisplayToolkitPopup: false,
    isToolkitForWalletReferrals: false,
}
const mutations = {
    [SET_SHOULD_DISPLAY_TOOLKIT_POPUP](state, shouldShow = false) {
        state.shouldDisplayToolkitPopup = shouldShow
    },
    [SET_IS_TOOLKIT_FOR_WALLET_REFERRALS](state, shouldShow = false) {
        state.isToolkitForWalletReferrals = shouldShow
    },
}
export default {
    state,
    mutations,
}

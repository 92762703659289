<template>
  <b-button
    v-if="isDashboardPage"
    class="kpa-floating-button d-flex align-items-center font-inter"
    variant="custom-secondary"
    @click="onClickKnowledgeCenter(true)"
  >
    <feather-icon
      icon="BookOpenIcon"
      size="20"
      class="mr-1"
    />
    <div>
      Learn to use Kingpin
    </div>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { onClickKnowledgeCenter } from '@/router/routes/apps'

export default {
    name: 'ScrollToTop',
    components: {
        BButton,
    },
    data() {
        return {
            onClickKnowledgeCenter,
        }
    },
    computed: {
        isDashboardPage() {
            return this.$route.name === 'dashboard'
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/base/bootstrap-extended/_variables.scss';

.kpa-floating-button {
    position: fixed;
    bottom: 5%;
    left: 80px;
    z-index: 1000;
    margin-left: 2rem;
    font-size: 20px;
    border: 2px solid #b950ff;
    background: #d28fff;
    font-weight: 800;
    padding-left: 40px;
    padding-right: 40px;
}
</style>

<template>
  <div>
    <b-button
      class="guest-sign-up"
      variant="none"
      @click="openModal"
    >
      Sign up now
    </b-button>
    <b-modal
      ref="guestSignup"
      hide-header
      hide-footer
      centered
      size="sm"
    >
      <div class="">
        <div class="pb-1 border-bottom">
          <span class="custom-modal-heading color-dark-black"> Kingpin Platform</span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            fill="black"
            size="24"
            color="black"
            @click="closeModal"
          />
        </div>
        <b-button
          variant="none"
          class="w-100 mt-1 guest-btn"
          @click="closeModal"
        >
          Continue as a guest
        </b-button>
        <div class="d-flex align-items-center mt-1">
          <hr class="w-50">
          <span class="my-5-px font-weight-bold">OR</span>
          <hr class="w-50">
        </div>
        <div>
          <div class="font-weight-bolder color-dark-black my-1">
            Sign in or register
          </div>
          <validation-observer
            #default="{ invalid }"
          >
            <b-form @submit.prevent="onSubmit">
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email"
              >
                <b-form-group
                  class="position-relative"
                  :class="
                    guestEmail ? errors.length > 0 ? 'invalid-input-group' : 'valid-input-group' : ''
                  "
                >
                  <validation-icon-with-tooltip
                    id="email-validation"
                    class="kp-validation-icon info-icon"
                    :show="!!(errors.length > 0)"
                    :errors="errors"
                  />
                  <b-form-input
                    v-model="guestEmail"
                    type="email"
                    class="guest-email-input"
                    placeholder="Guest@example.com"
                  />
                </b-form-group>
              </validation-provider>
              <b-button
                variant="info"
                class="w-100 my-1 font-weight-bolder"
                type="submit"
                :disabled="invalid"
              >
                Continue
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
 BButton, BModal, BFormInput, BForm, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ValidationIconWithTooltip from '@/@core/components/icon-with-tooltip-error/ValidationIconWithTooltip.vue'
import store from '@/store'
import { PURGE_AUTH } from '@/store/modules/auth.module'
import { AUTH_REGISTER } from '@/router/routes/pages'

export default {
    components: {
        BButton,
        BModal,
        BFormInput,
        BForm,
        BFormGroup,
        ValidationObserver,
        ValidationProvider,
        ValidationIconWithTooltip,
    },
    data() {
        return {
            guestEmail: '',
            required,
            email,
        }
    },
    mounted() {
      this.$root.$refs.guestSignup = this
    },
    methods: {
        onSubmit() {
            store.commit(PURGE_AUTH)
            this.$router.push({ name: AUTH_REGISTER, query: { email: this.guestEmail } })
            this.closeModal()
        },
        openModal() {
            this.$refs.guestSignup.show()
        },
        closeModal() {
            this.$refs.guestSignup.hide()
        },
    },
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/pages/page-auth.scss';
@import '~@core/scss/vue/pages/signup/signup-page.scss';
@import '~@core/scss/vue/pages/signup/kp-signup.scss';
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
.guest-sign-up{
  &.btn{
    position: fixed;
    left: 110px;
    bottom: 32px;
    background: $dark-blue;
    color: $white !important;
    border-radius: 40px;
    transition: all linear .3s;
    font-size: 16px;
    font-weight: 700;
    z-index: 15;
    height: 44px;
  }
}
.info-icon{
    top: 7px;
}
.guest-btn{
    &.btn{
        color: $dark-blue;
        border: 1px solid $dark-blue;
        font-weight: 600;
        &:hover{
            color: $dark-blue;
        }
    }
}
</style>

/* eslint-disable */
import { TOOLKIT_PAGE } from './constants'

export const steps = {
  // Steps for Retailer
  retailer: {
    [TOOLKIT_PAGE.WELCOME_TO_KINGPIN]: [
      {
        title: '<div class="for-welcome-image"></div>',
        intro: `
      <p class="for-welcome-heading">Welcome to Kingpin!</p>
      <div class="for-border-bottom">&nbsp;</div>
      <p class="for-welcome-content">We're excited to have you onboad. Over the next few minutes, we will run you through a quick walk through on how to set up your profile, browse through collections and manage orders.</p>
      `,
      },
    ],
    [TOOLKIT_PAGE.PROFILE]: [
      {
        title: 'Go to Profile',
        element: '#intro_profile',
        intro: "Firstly, we begin by setting up your profile. It's important to make sure all the sections are completed correctly so your onboarding is complete and you can start placing orders.",
        position: 'right',
      },
      {
        title: 'Profile | General ',
        element: '#intro_general',
        intro: 'Please make sure to share the best representation of your store, clearly and correctly, so that our International Brands can get excited to know you and work with you.',
        position: 'right',
      },
      {
        title: 'Profile | Segmentation',
        element: '#intro_segmentation',
        intro: 'Choose the categories and target pricepoints you are interested in buying, so that the Kingpin team can share new Brand Introductions and Seasonal Merchandising Guides to you through our newsletters.',
        position: 'right',
      },
      {
        title: 'Profile | Documents',
        element: '#into_documents',
        intro: '*It is mandatory to upload your Trade License and VAT Registration details in order to place orders',
        position: 'right',
      },
      {
        title: 'Profile | Addresses',
        element: '#intro_addresses',
        intro: 'Enter your Billing Address (*As per Trade License) and Shipping Address here and you are ready to go!',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.BRANDS_EXPLORE]: [
      {
        title: 'Go to Explore | All Brands',
        element: '.intro-view-all-brands',
        intro: 'Once your Profile is set up, you are able to Browse and Place orders through Kingpin.',
        position: 'right',
      },
      {
        title: 'Explore | Filters',
        element: '#intro_filters',
        intro: 'Filter through the universe of brands available on Kingpin by Department, Average Retail and Wholesale Price Points. ',
        position: 'right',
      },
      {
        title: 'Explore | Select a Brand',
        element: '#brand0',
        intro: "Once you've narrowed down your brands of interest, click on a brand to access their profile page.",
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.BRAND_PROFILE]: [
      {
        title: 'Brand Profile',
        element: '#intro_brand_info',
        intro: 'Review all the key highlights on each brand and access their latest collections all in one place.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.SHOP_PRODUCTS]: [
      {
        title: 'Shop Products | Filters',
        element: '#intro_filters',
        intro: 'Seamlessly filter through full catalogues directly from brand principles by Category, Subcategory, Colors, Sizes and more.',
        position: 'right',
      },
      {
        title: 'Shop Products | Add to Wishlist',
        element: '.intro-add-wishlist',
        intro: 'Make selections easily and effectively by adding products that you love to your wishlist. Want more details? Click on the product image to find out more!',
        position: 'right',
      },
      {
        title: 'Shop Products | Add to Cart',
        element: '.intro-add-cart',
        intro: "Once you're happy with your selection, add products directly to your cart to insert quantities at the time of Checkout. You can do this one at a time or select multiple products and add them in one go! ",
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.WISHLIST]: [
      {
        title: 'Go to Wishlist',
        element: '#intro_whish_list',
        intro: "Once you're done browsing, go to Wishlist to review your selections.",
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.CHECKOUT]: [
      {
        title: 'Go to Checkout',
        element: '#intro_checkout',
        intro: 'Go to Checkout to finalise your order for the products you have moved to your cart.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.ORDERS]: [
      {
        title: 'Order Management',
        element: '#intro_orders',
        intro: 'Get a birdseye view on all your orders across various brands and their statuses through a single dashboard here.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.DASHBOARD]: [
      {
        title: 'Dashboard',
        element: '#intro_dashboard',
        intro: 'Simplify your entire buying operation through a single dashboard on Kingpin.',
        position: 'right',
      },
      {
        title: "We're here to support!",
        element: '#intro_help',
        intro: "And that's a wrap! Don't worry, we're here to support you. Click on Help and schedule a call anytime with our Concierge service for support on any queries you may have.",
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.REFERRALS]: [
      {
        title: '1/5. Quickly access your Referrals',
        element: '#intro_referrals',
        intro: `<div>Keep updated on the status of your referrals on the <b>Referrals Page</b></div>`,
        position: 'right',
      },
      {
        title: '2/5. Invite a Brand and earn up to $5000!',
        element: '#intro_referral_button',
        intro: 'Invite brands to join Kingpin and earn credit points to spend on your orders',
        position: 'left',
      },
    ],
    [TOOLKIT_PAGE.WALLET]: [
      {
        title: '3/5. Quickly access your wallet',
        element: '#intro_wallet',
        intro: `<div>Quickly access your transactions and rewards on the <b>Wallet Page</b></div>`,
        position: 'right',
      },
      {
        title: '4/5. Transactions Tab',
        element: '#intro_transactions_tab',
        intro: `<div>Find your transactions in this tab on the <b>Wallet Page</b></div>`,
        position: 'right',
      },
      {
        title: '5/5. Rewards Tab',
        element: '#intro_rewards_tab',
        intro: 'Track your rewards in your very own <b>Rewards Dashboard</b>',
        position: 'right',
      },
    ],
  },

  // Steps for Brand
  brand: {
    [TOOLKIT_PAGE.WELCOME_TO_KINGPIN]: [
      {
        title: '<div class="for-welcome-image"></div>',
        intro: `
      <p class="for-welcome-heading">Welcome to Kingpin!</p>
      <div class="for-border-bottom">&nbsp;</div>
      <p class="for-welcome-content">We're excited to have you onboad. Over the next few minutes, we will run you through a quick walk through on how to set up your storefront, create beautiful catalogues of your products and manage orders.</p>
      `,
      },
    ],
    [TOOLKIT_PAGE.PROFILE]: [
      {
        title: 'Go to Profile',
        element: '#intro_profile',
        intro: "Firstly, we begin by setting up your profile. It's important to make sure all the sections are completed correctly so your onboarding is complete and you can begin receiving orders from Retailers.",
        position: 'right',
      },
      {
        title: 'Profile | General ',
        element: '#intro_general',
        intro: 'Please make sure to share the best representation of your Brand by way of Logos, Banners, Profiles, and Descriptions. This will serve as a quick window into your business, so our retail partners can get excited to know and work with you!',
        position: 'right',
      },
      {
        title: 'Profile | Segmentation',
        element: '#intro_segmentation',
        intro: 'Choose the categories and target price points that best fit your products. This will allow relevant Retailers to effectively search and access your storefront. It will also help our team to accurately target retailers for your Brand through our newsletter introductions and seasonal merchandising guides.',
        position: 'right',
      },
      {
        title: 'Profile | Documents',
        element: '#into_documents',
        intro: 'Kindly provide current copies of your Trade License/Commercial Registration, and VAT Certificate here, followed by the corresponding Registration numbers.',
        position: 'right',
      },
      {
        title: 'Profile | Addresses',
        element: '#intro_addresses',
        intro: 'Enter your Billing Address (*As per Trade License) and Shipping Address (Your consolidated warehouse from where goods will be collected) here and you are ready to go!',
        position: 'right',
      },
      {
        title: 'Profile | Preview',
        element: '#intro_preview',
        intro: 'After adding all details, preview your Profile and tweak it to perfection as to what Retailers will see when accessing your storefront.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.BRAND_LANDING_PAGE]: [
      {
        title: 'Profile | Preview',
        intro: 'The Preview page allows you to view what retailers will see when they first open your Brand Profile. Here you can check if the Logo + Banners you uploaded are sized and displayed correctly, and ensure your Segmentation + Price Points are accurate. You can also view any existing Collections here once uploaded.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.COLLECTIONS]: [
      {
        title: 'Go to Collections | Add Collection',
        element: '#intro_add_collection_button',
        intro: "Once your Profile set-up is complete, you're now ready to begin adding your collections. Catalogs can be uploaded as either Pre-Book Collections or Immediate Delivery.",
        position: 'left',
      },
    ],
    [TOOLKIT_PAGE.COLLECTION_EDIT]: [
      {
        title: 'Add Collection | Collection Name',
        element: '#intro_collection_name',
        intro: "The first step to Add a Collection is providing the relevant Collection name (i.e. Spring/Summer '22, Immediate Delivery '22, Resort/Holiday '22 ). Please note each collection will need to be created separately, and will require a seperate product/image upload.",
        position: 'bottom',
      },
      {
        title: 'Add Collection | Description',
        element: '#intro_collection_description',
        intro: "Provide a short profile on each collection here, in order for new customers to view any highlights, collaborations, or any background on the collection's inspiration.",
        position: 'bottom',
      },
      {
        title: 'Add Collection | Products Availability',
        element: '#intro_collection_availability',
        intro: "Let retailers know if products are in stock by selecting product availability option.",
        position: 'bottom',
      },
      {
        title: 'Add Collection | Expiry Date',
        element: '#intro_collection_end_date',
        intro: "Provide a Collection Cut-off/Deadline date, for retailers to understand your timelines. It's important to note that no orders will be received after this cut-off date.",
        position: 'bottom',
      },
      {
        title: 'Add Collection | Collection Cover Photo',
        element: '#intro_collection_logo',
        intro: 'Add a beautiful Cover Photo that visually represents the Collection by uploading a .jpg/.png file directly onto the platform.',
        position: 'top',
      },
      {
        title: 'Add Collection | Sample Product Upload Sheet',
        element: '#intro_sample_product_sheet',
        intro: `The Product Upload Template functions as your master key for all collection uploads, as this is where you can highlight key catalog features. Let Retailers know whether you sell in Packs/Cartons or Free-Size in Column R (isCarton). Select "Yes" for Packs, or "No" for Free-Size. Designate up to 5 Images per product by adding relevant Image Links, and let retailers know any applicable Shipment Availability windows per style. 
              
              We're here to help! So don't hesitate to contact Support if you have any questions!`,
        position: 'top',
      },
      {
        title: 'Add Collection | Mass Upload Products',
        element: '#intro_collection_products',
        intro: `This is where you have the ability to directly upload your catalog onto our platform. 
              
              Pro-tips: Save the Upload Template as a CSV file, and rename to "products.csv". In the upload folder, provide all product images (make sure image names match those provided in CSV file). Make sure the Upload Folder contains the CSV file + All Images. Upload the full folder as a Zip file directly onto the platform, hit "Save", and voila! As always, if you have any trouble with the Upload process, our Support team is here for you.`,
        position: 'top',
      },
    ],
    [TOOLKIT_PAGE.ORDERS]: [
      {
        title: 'Order Management',
        element: '#intro_orders',
        intro: 'Get a birdseye view on all your orders across various retailers and their statuses through a single dashboard here.',
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.DASHBOARD]: [
      {
        title: 'Dashboard',
        element: '#intro_dashboard',
        intro: 'Simplify your entire Wholesale Sales operation through a single dashboard on Kingpin.',
        position: 'right',
      },
      {
        title: "We're here to support!",
        element: '#intro_help',
        intro: "And that's a wrap! Don't worry, we're here to support you. Click on Help and schedule a call anytime with our Concierge service for support on any queries you may have.",
        position: 'right',
      },
    ],
    [TOOLKIT_PAGE.REFERRALS]: [
      {
        title: '1/5. Quickly access your Referrals',
        element: '#intro_referrals',
        intro: `<div>Keep updated on the status of your referrals on the <b>Referrals Page</b></div>`,
        position: 'right',
      },
      {
        title: '2/5. Invite a retailer and deal with ZERO commission',
        element: '#intro_referral_button',
        intro: 'Invite a new retailer to join Kingpin and get ZERO commission on the first order from this retailer',
        position: 'left',
      },
    ],
    [TOOLKIT_PAGE.WALLET]: [
      {
        title: '3/5. Quickly access your wallet',
        element: '#intro_wallet',
        intro: `<div>Quickly access your transactions and savings on the <b>Wallet Page</b></div>`,
        position: 'right',
      },
      {
        title: '4/5. Transactions Tab',
        element: '#intro_transactions_tab',
        intro: `<div>Find your transactions in this tab on the <b>Wallet Page</b></div>`,
        position: 'right',
      },
      {
        title: '5/5. Savings Tab',
        element: '#intro_savings_tab',
        intro: 'Track your savings on Kingpin commissions on your very own <b>Rewards Dashboard</b>',
        position: 'right',
      },
    ],
  },
}

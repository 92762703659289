<script>
import store from '@/store'
// These are repeatedly used variables from store
// If you declare this mixin file in component you can directly use these variables without importing
export default {
    computed: {
        isRetailer() {
            return store.getters.isRetailer
        },
        isBrand() {
            return store.getters.isBrand
        },
        isGuest() {
            return store.getters.isGuest
        },
        isEntityAdmin() {
            return store.getters.isEntityAdmin
        },
        isSalesManager() {
            return store.getters.isSalesManager
        },
        isSalesRep() {
            return store.getters.isSalesRep
        },
        isAdmin() {
            return store.getters.isAdmin
        },
        isDemo() {
            return store.getters.isDemo
        },
        role() {
            return store.getters.role
        },
        entityType() {
            return store.getters.entityType
        },
    },
}
</script>

export const getAuthDetails = data => {
    let { entityType } = data
    if (!entityType) {
        entityType = data.role
    }

    return {
        ...data,
        entityType,
    }
}

<template>
  <li
    class="timeline-item"
    :class="!visible ? 'timeline-item-background':''"
  >
    <slot>
      <div
        v-b-tooltip.hover.right="hovermsg"
        class="timelineCont timeline-item-invisible"
        :class="visible && nextData ? 'timeline-item-visible':''"
      >
        <b-img
          :src=" !visible ? roundedUncheckIcon : roundedCheckIcon"
          class="timeline-img"
        />
        <b-link
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 title"
          :class="visible ? 'visible':'' "
          :to="{name:link,params:{
            tabIndex:tabIndex
          }}"
          @click="closePopup"
        >
          {{ title }}
        </b-link>
      </div>
    </slot>
  </li>
</template>

<script>
import {
  BLink, BImg, VBTooltip,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BImg,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: null,
    },
    hovermsg: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: null,
    },
    nextData: {
      type: Boolean,
      default: false,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      roundedCheckIcon: require('@/assets/images/icons/roundedCheckIcon.svg'),
      roundedUncheckIcon: require('@/assets/images/icons/roundedUncheckIcon.svg'),
    }
  },
  methods: {
    closePopup() {
      if (this.isPopup) {
        this.$root.$refs.checklistPopup._data.showPopup = false
      }
      analytics.track(constants.TRACKS.ACTIONS.USER_CLICKS_ONBOARDING_CHECKLIST_ITEM_ON_DASHBOARD, { title: this.title })
    },
  },
}
</script>

<template>
  <div
    class="toastification-center"
    :class="`toastification-${variant}`"
    v-html="title"
  />
</template>

<script>
export default {
  name: 'ToastificationMessage',
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: null,
    },
  },
}
</script>

import Vue from 'vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import ToastificationMessage from '@/@core/components/toastification/ToastificationMessage.vue'
import { parseErrorObject } from './utils'

export function apiToastSuccess(title, icon, text) {
  icon = icon || 'CheckCircleIcon'
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon,
      variant: 'success',
    },
  })
}

export function apiToastWarning(title, icon, text) {
  icon = icon || 'AlertCircleIcon'
  if (typeof title === 'object') {
    title = parseErrorObject(title)
  }
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon,
      variant: 'warning',
    },
  })
}

export function apiToastError(title, icon, text) {
  icon = icon || 'AlertTriangleIcon'
  if (typeof title === 'object') {
    title = parseErrorObject(title)
  }
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon,
      variant: 'danger',
    },
  })
}
export function apiToastErrorV2(title) {
  if (typeof title === 'object') {
    title = parseErrorObject(title)
  }
  Vue.prototype.$toast({
    component: ToastificationMessage,
    props: {
      title,
      variant: 'danger',
    },
  }, {
    position: 'top-center',
    toastClassName: 'custom-toast',
  })
}
export function apiToastSuccessV2(title) {
  if (typeof title === 'object') {
    title = parseErrorObject(title)
  }
  Vue.prototype.$toast({
    component: ToastificationMessage,
    props: {
      title,
      variant: 'success',
    },
  }, {
    position: 'top-center',
    toastClassName: 'custom-toast',
  })
}

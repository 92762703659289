import { FETCH_PROFILE } from '@/store/modules/auth.module'
import { GET_COMMON_AUTH } from '@/store/modules/common.module'
import { FETCH_CART_COUNT } from '@/store/modules/shop.module'

export const dispatchFetchProfile = async dispatch => await dispatch(FETCH_PROFILE)

export const dispatchGetCommonAuth = async (dispatch, params) => await dispatch(GET_COMMON_AUTH, { params })

export const dispatchFetchCartCount = async dispatch => await dispatch(FETCH_CART_COUNT)

export const getSelectionName = state => state.shop?.selectionName

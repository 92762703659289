import store from '@/store'

// Function to recursively flatten userScope arrays
export const flattenUserScopes = (userScope = []) => {
    let flattenedScopes = []
    userScope?.forEach(scope => {
        flattenedScopes.push(scope)
        if (Array.isArray(scope.userScope)) {
            flattenedScopes = flattenedScopes.concat(flattenUserScopes(scope.userScope))
        }
    })
    return flattenedScopes
}

export const scopesFromUserAssociations = (userAssociations = []) => {
    let scopes = []
    userAssociations.forEach(association => {
        if (association?.userScope?.length) {
            scopes = scopes.concat(flattenUserScopes(association.userScope))
        }
    })
    return scopes
}

export const setEntityNameInUserObject = (userObj = {}) => {
  userObj.entityName = userObj?.entityName || `${userObj?.firstName} ${userObj?.lastName}`
}

export const getSelfOption = () => {
    const currentUserId = store.getters?.profile?._id
  return {
    name: 'Created by me',
    userId: currentUserId,
  }
}

import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import analytics from '@/@core/utils/analytics'
import {
  getHomeRouteForLoggedInUser,
  setRedirectRouteData,
} from '@/auth/utils'
import { kpEndpoint, kpRequest } from '@/axios'
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  saveAbility,
  saveAdmin,
  saveEntityType,
  saveRole,
  saveToken,
} from '@/store/jwt.service'
import {
  PURGE_AUTH,
  SET_ENTITY_TYPE,
  SET_LOGIN_USING_LINK,
  SET_PROFILE,
  SET_ROLE,
  SET_AUTHENTICATED,
  FETCH_ALL_SCOPES,
} from '@/store/modules/auth.module'
import { INIT_SHOP } from '@/store/modules/shop.module'
import constants from '@/constants'
import { formatObject } from '@/@core/utils/utils'
import { getAuthDetails } from '@/service/auth-middleware.service'
import { Settings } from '@/Settings'
import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: undefined,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: () => getHomeRouteForLoggedInUser(store.getters.entityType),
    },
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // check for magicLink token before routing
  if (
    (!from.query.magicLink && to.query.magicLink)
    || (!from.query.loginLink && to.query.loginLink)
  ) {
    if (to.path !== from.path) {
      const token = to.query.magicLink || to.query.loginLink
      const isAdmin = to.query.isAdmin || false
      store.commit(SET_PROFILE, null)
      store.commit(PURGE_AUTH)

      // verify user
      const user = await kpRequest({
        ...kpEndpoint.profile.get,
        baseURL: Settings.getConfig('VUE_APP_BACKEND_URL'),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => res.data.data)
        .catch(() => null)
      // Update magin link login date
      if (isAdmin) {
        await kpRequest({
          ...kpEndpoint.profile.magicLinkLogin,
          payload: {},
          baseURL: Settings.getConfig('VUE_APP_BACKEND_URL'),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      }
      // user exist
      if (user) {
        let trackObj
        if (isAdmin) {
          trackObj = {
            via_magic_link: true,
          }
        }
        analytics.track(
          constants.TRACKS.PAGES.USER_LOGGED_IN_USING_MAGIC_LINK,
          formatObject(trackObj),
        )
        const authDetails = getAuthDetails(user)
        // flush current token
        store.commit(SET_PROFILE, authDetails)
        saveToken(token)
        saveRole(authDetails.role)
        saveEntityType(authDetails.entityType)
        store.commit(SET_AUTHENTICATED)
        store.commit(SET_ROLE)
        store.commit(SET_ENTITY_TYPE)
        store.dispatch(FETCH_ALL_SCOPES)
        Vue.prototype.$ability.update(saveAbility())
        await router.push(getHomeRouteForLoggedInUser(authDetails.entityType))
        if (isAdmin) {
          analytics.reset()
          saveAdmin()
          store.commit('setAdmin')
        } else {
          store.commit(SET_LOGIN_USING_LINK, true)
        }
        setRedirectRouteData(null)
        // init shop
        store.commit(INIT_SHOP)
      }
    }
  }

  if (to.meta.guest) {
    return next()
  }

  const isLoggedIn = store.getters.isAuthenticated

  const canUserNavigate = await canNavigate(to)
  if (!canUserNavigate) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      setRedirectRouteData(to)
      return next({ name: 'auth-login' })
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser(store.getters.entityType))
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

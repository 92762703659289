import { kpRequest, kpEndpoint } from '@/axios'
// Referral mails
export const SEND_REFERRAL_MAIL = 'sendReferralMail'
export const GET_REFERRALS_LIST = 'getReferralsList'
export const VALIDATE_REFERRAL = 'validateReferral'
export const REFERRAL_INVITE = 'referralInvite'
export const UPDATE_REFERRAL_WALLET_RULE = 'updateReferralWalletRule'

const actions = {
    async [SEND_REFERRAL_MAIL](ctx, payload = {}) {
        return await kpRequest({ ...kpEndpoint.user.referrals.sendMail, payload })
    },
    async [GET_REFERRALS_LIST]() {
        return await kpRequest({ ...kpEndpoint.user.referrals.get })
    },
    async [VALIDATE_REFERRAL](ctx, payload = {}) {
        return await kpRequest({ ...kpEndpoint.user.referrals.validate, payload })
    },
    async [REFERRAL_INVITE](ctx, payload = {}) {
        return await kpRequest({ ...kpEndpoint.user.referrals.invite, payload })
    },
    async [UPDATE_REFERRAL_WALLET_RULE](ctx, payload = {}) {
        return await kpRequest({ ...kpEndpoint.user.referrals.updateWalletRule, payload })
    },
}

export default {
    actions,
}

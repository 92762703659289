import { kpRequest, kpEndpoint, kpParams } from '@/axios'

export const CONFIRMED_ORDERS = 'confirmedOrders'
export const DOWNLOAD_ORDER_INVOICE = 'downloadOrderInvoice'
export const GET_ORDER = 'getOrder'
export const ORDER_IMPORT = 'orderImport'
export const UPDATE_ORDER_PRODUCT_STATUS = 'updateOrderProductStatus'
export const UPDATE_ORDER = 'updateOrder'
export const MODIFY_ORDER_PRODUCTS = 'modifyOrderProducts'
export const GET_ORDER_LIST = 'getOrderList'
export const GET_ORDER_FILTER_OPTIONS = 'getOrderFilterOptions'
export const CREATE_ORDER = 'createOrder'
export const APPROVE_ORDER = 'approveOrder'
export const REJECT_ORDER = 'rejectOrder'
export const CANCEL_ORDER = 'cancelOrder'
export const ADD_ORDER_PRODUCTS = 'addOrderProducts'
export const DOWNLOAD_ORDER = 'downloadOrder'
export const ORDER_PRODUCTION_FILE_EXPORT = 'orderProductionFileExport'
export const CREATE_MULTIPLE_ORDER = 'createMultipleOrder'

const actions = {
  async [UPDATE_ORDER](ctx, { orderId, payload = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.patch, orderId), payload })
  },
  async [CONFIRMED_ORDERS](ctx, collectionId) {
    const params = {
      collectionId,
      confirmedOrders: true,
    }
    return await kpRequest({ ...kpEndpoint.order.get, params })
  },
  async [DOWNLOAD_ORDER_INVOICE](ctx, orderId) {
    return await kpRequest(
      {
      ...kpParams(kpEndpoint.order.downloadInvoice, orderId),
      ...{
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      },
     },
    )
  },
  async [GET_ORDER](ctx, orderId) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.getById, orderId) })
  },
  async [MODIFY_ORDER_PRODUCTS](ctx, { orderId, params = {}, payload = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.modify, orderId), payload, params })
  },
  async [ORDER_IMPORT](ctx, { orderId, payload = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.import, orderId), payload })
  },
  async [UPDATE_ORDER_PRODUCT_STATUS](ctx, { orderId, payload = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.updateProductStatus, orderId), payload })
  },
  async [GET_ORDER_LIST](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.order.get, params })
  },
  async [GET_ORDER_FILTER_OPTIONS](ctx, { searchField, params = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.filterByFieldName, searchField), params })
  },
  async [CREATE_ORDER](ctx, payload = { }) {
    return await kpRequest({ ...kpEndpoint.order.create, payload })
  },
  async [CREATE_MULTIPLE_ORDER](ctx, payload = { }) {
    return await kpRequest({ ...kpEndpoint.order.createMultiple, payload })
  },
  async [APPROVE_ORDER](ctx, orderId) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.approve, orderId) })
  },
  async [REJECT_ORDER](ctx, orderId) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.reject, orderId) })
  },
  async [CANCEL_ORDER](ctx, { orderId, params = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.cancel, orderId), params })
  },
  async [ADD_ORDER_PRODUCTS](ctx, { orderId, payload }) {
    return await kpRequest({ ...kpParams(kpEndpoint.order.addOrderProducts, orderId), payload })
  },
  async [DOWNLOAD_ORDER](ctx, { payload = {}, params = {} }) {
    return await kpRequest({ ...kpEndpoint.downloads.order, params, payload })
  },
  async [ORDER_PRODUCTION_FILE_EXPORT](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.order.productionExport, params })
  },
}

export default {
  actions,
}

import { kpRequest, kpEndpoint, kpParams } from '@/axios'

export const DOWNLOAD_SHIPMENT_INVOICE = 'downloadShipmentInvoice'
export const UPDATE_SHIPMENT = 'updateShipment'
export const GET_SHIPMENT = 'getShipment'
export const GET_SHIPMENT_LIST = 'getShipmentList'
export const GET_SHIPMENT_LIST_ENTITY_NAMES = 'getShipmentListEntityNames'
export const CREATE_SHIPMENT = 'createShipment'

const actions = {
  async [DOWNLOAD_SHIPMENT_INVOICE](ctx, shipmentId) {
    return await kpRequest({
 ...kpParams(kpEndpoint.shipment.downloadInvoice, shipmentId),
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        })
  },
  async [UPDATE_SHIPMENT](ctx, { shipmentId, payload }) {
    return await kpRequest({ ...kpParams(kpEndpoint.shipment.update, shipmentId), payload })
  },
  async [GET_SHIPMENT](ctx, shipmentId) {
    return await kpRequest({ ...kpParams(kpEndpoint.shipment.getById, shipmentId) })
  },
  async [GET_SHIPMENT_LIST](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.shipment.get, params })
  },
  async [GET_SHIPMENT_LIST_ENTITY_NAMES](ctx, { role, params = {} }) {
    return await kpRequest({ ...kpParams(kpEndpoint.shipment.filterByEntityName, role), params })
  },

  async [CREATE_SHIPMENT](ctx, payload = {}) {
    return await kpRequest({ ...kpEndpoint.shipment.create, payload })
  },
}

export default {
  actions,
}

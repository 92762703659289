import { kpEndpoint, kpParams, kpRequest } from '@/axios'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { dispatchFetchCartCount, getSelectionName } from '@/store/helpers/store-helpers'
import analytics from '@/@core/utils/analytics'
import constants, { FILE_FORMATS } from '@/constants'
import { getEntityType } from '../jwt.service'

const { ROLES } = c
const { MULTI_SELECT_PRODUCTS } = constants.TRACKS.ACTIONS

export const isEnabledHintPopup = 'isEnabledHintPopup'

// Cart Related actions

export const ADD_PRODUCT_TO_CART = 'addProductToCart'
export const FETCH_CART_COUNT = 'fetchCartCount'
export const FETCH_CART_PRODUCTS = 'fetchCartProducts'
export const CLEAR_CART = 'clearCart'
export const REMOVE_PRODUCT_FROM_CART = 'removeProductFromCart'
export const IMPORT_CART = 'importCart'
export const EXPORT_CART = 'exportCart'
export const EXPORT_CART_MULTIPLE = 'exportCartMultiple'
export const REMOVE_PRODUCT_FROM_CART_BY_CART_ID = 'removeProductFromCartByCartId'
export const UPDATE_CART_NOTES = 'updateCartNotes'
export const GET_PRODUCTS_BY_IDS = 'getProductsByIds'

// Wishlist & Selection Related actions

export const ADD_PRODUCT_TO_WISHLIST = 'addProductToWishlist'
export const FETCH_WISHLIST_PRODUCTS = 'fetchWishlistProducts'
export const FETCH_WISHLIST_PRODUCTS_GUEST = 'fetchWishlistProductsGuest'
export const FETCH_WISHLISTS = 'fetchWishlists'
export const FETCH_WISHLIST_FILTERS = 'fetchWishlistFilters'
export const REMOVE_WISHLIST_PRODUCT = 'removeWishlistProduct'
export const REMOVE_MULTIPLE_WISHLIST_PRODUCTS = 'removeMultipleWishlistProducts'
export const DELETE_WISHLIST = 'deleteWishlist'
export const IMPORT_WISHLIST = 'importWishlist'
export const DOWNLOAD_WISHLIST = 'downloadWisht'
export const SHARE_LINK_WISHLIST = 'shareLinkWishlist'
// shop related mutations and state

export const INIT_SHOP = 'initShop'
export const UPDATE_CART_ITEMS_COUNT = 'updateCartItemsCount'
export const UPDATE_CURRENT_CHECKOUT_WISHLIST = 'updateCurrentCheckoutWishlist'
export const UPDATE_SELECT_COUNT = 'updateSelectCount'
export const UPDATE_SELECT_TYPE = 'updateSelectType'
export const SET_REPLACE_CART_PRODUCT = 'setReplaceCartProduct'
export const SET_SELECTION_NAME = 'setSelectionName'
export const UPDATE_WISHLIST = 'updateWishlist'
export const UPDATE_DIRECT_BRANDS = 'updateDirectBrands'
export const UPDATE_MARKETPLACE_BRANDS = 'updateMarketplaceBrands'
export const UPDATE_SELECTED_DIRECT_RETAILER = 'updateSelectedDirectRetailer'
export const UPDATE_IS_SELECTED_ALL_PRODUCTS = 'updateIsSelectedAllProducts'
export const UPDATE_TOTAL_PRODUCTS_COUNT = 'updateTotalProductsCount'
export const SET_IS_NO_HOVER_PRODUCT_CARD = 'setIsNoHoverProductCard'

export const MULTI_SELECT_PAGES = {
  WISHLIST: 'WISHLIST',
  SHOP: 'SHOP',
}

let debounceValue = null

export const state = {
  cartItemsCount: 0,
  wishlists: [],
  selectCount: 0,
  selectType: '',
  currentCheckoutWishlist: null,
  replaceCartProduct: null,
  selectionName: '',
  directBrands: [],
  selectedDirectRetailer: null,
  isSelectedAllProducts: false,
  totalProductsCount: 0,
  showMultiSelectNavBarForProductsPage: false,
  isNoHoverProductCard: false,
}
export const getters = {
  wishlists(state) {
    return state.wishlists
  },
  getCurrentCheckoutWishlist(state) {
    return state.currentCheckoutWishlist
  },
  getReplaceCarProduct(state) {
    return state.replaceCartProduct
  },
  directBrands(state) {
    return state?.directBrands || []
  },
  selectedDirectRetailer(state) {
    return state?.selectedDirectRetailer || null
  },
  isSelectedAllProducts(state) {
    return state.isSelectedAllProducts
  },
  totalProductsCount(state) {
    return state.totalProductsCount
  },
}
export const mutations = {
  [INIT_SHOP]() {
    const role = getEntityType()
    if (role === ROLES.RETAILER) {
      dispatchFetchCartCount(this.dispatch)
    }
  },
  [UPDATE_CART_ITEMS_COUNT](state, count) {
    state.cartItemsCount = count
  },
  [UPDATE_CURRENT_CHECKOUT_WISHLIST](state, wishlist) {
    state.currentCheckoutWishlist = wishlist
    return state.currentCheckoutWishlist
  },
  [UPDATE_SELECT_COUNT](state, count) {
    if (count) {
      if (debounceValue) {
        clearTimeout(debounceValue)
      }
      state.selectCount = count
      if (!state.showMultiSelectNavBarForProductsPage) {
        state.showMultiSelectNavBarForProductsPage = true
        analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_OPENED, {
          selectedProductsCount: count,
          currentPage: state.selectType,
        })
      }
      return
    }
    debounceValue = setTimeout(() => {
      state.showMultiSelectNavBarForProductsPage = false
      analytics.track(MULTI_SELECT_PRODUCTS.BOTTOM_TOOLBAR_CLOSED)
    }, 500)
    state.selectCount = 0
  },
  [UPDATE_SELECT_TYPE](state, type) {
    state.selectType = type
  },
  [SET_REPLACE_CART_PRODUCT](state, product) {
    state.replaceCartProduct = product
  },
  [SET_SELECTION_NAME](state, text = '') {
    state.selectionName = text
  },
  [UPDATE_WISHLIST](state, wishlists) {
    state.wishlists = wishlists
  },
  [UPDATE_DIRECT_BRANDS](state, directBrands) {
    state.directBrands = directBrands
  },
  [UPDATE_SELECTED_DIRECT_RETAILER](state, selectedDirectRetailer) {
    state.selectedDirectRetailer = selectedDirectRetailer
  },
  [UPDATE_IS_SELECTED_ALL_PRODUCTS](state, isSelectedAllProducts) {
    state.isSelectedAllProducts = isSelectedAllProducts
  },
  [UPDATE_TOTAL_PRODUCTS_COUNT](state, totalProductsCount) {
    state.totalProductsCount = totalProductsCount
  },
  [SET_IS_NO_HOVER_PRODUCT_CARD](state, val = false) {
    if (typeof val === 'boolean') {
      state.isNoHoverProductCard = val
    }
  },
}
export const actions = {
  // Wishlist cart related apis

  async [ADD_PRODUCT_TO_WISHLIST](ctx, { payload = {}, queryParams = {} }) {
    if (payload.wishlistId) {
      return await kpRequest({
        ...kpParams(kpEndpoint.wishlist.put, payload.wishlistId),
        params: queryParams,
        payload,
      })
    } else {
      const selectionName = getSelectionName(ctx.state)
      if (selectionName) {
        payload.name = selectionName
      }
      return await kpRequest({
        ...kpEndpoint.wishlist.create,
        params: queryParams,
        payload,
      })
    }
  },
  async [FETCH_WISHLIST_PRODUCTS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.wishlist.get, params: payload })
  },
  async [FETCH_WISHLIST_PRODUCTS_GUEST](ctx, { publicToken, payload }) {
     return await kpRequest({
       ...kpParams(kpEndpoint.public.wishlist.get, publicToken),
       params: payload,
     })
  },
  async [FETCH_WISHLISTS](ctx, payload) {
    let force = false
    const queryParams = {}
    if (payload.force) {
      force = payload.force
    }
    if (payload.search) {
      queryParams.search = payload.search
    }
    if (payload.collectionId) {
      queryParams.collectionId = payload.collectionId
    }
    if (ctx.getters.wishlists.length && !force) {
      return ctx.getters.wishlists
    } else {
      return await kpRequest({
        ...kpEndpoint.wishlist.get,
        params: queryParams,
      })
        .then(response => {
          ctx.commit(UPDATE_WISHLIST, response.data.data)
          return response.data.data
        })
        .catch(error => error)
    }
  },
  async [FETCH_WISHLIST_FILTERS](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.wishlist.filter, params: payload })
  },
  async [REMOVE_WISHLIST_PRODUCT](ctx, { productId, wishlistId }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.wishlist.removeProduct, wishlistId, productId),
    })
  },
  async [REMOVE_MULTIPLE_WISHLIST_PRODUCTS](ctx, { products, wishlistId }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.wishlist.removeProducts, wishlistId),
      payload: { products },
    })
  },
  async [DELETE_WISHLIST](ctx, wishlistId) {
    return await kpRequest({
      ...kpParams(kpEndpoint.wishlist.delete, wishlistId),
    })
  },
  async [IMPORT_WISHLIST](ctx, { payload, params }) {
    return await kpRequest({ ...kpEndpoint.wishlist.import, payload, params })
  },
  async [DOWNLOAD_WISHLIST](ctx, { payload = {}, params = {} }) {
    return await kpRequest({
      ...kpEndpoint.downloads.wishlist,
      payload,
      params,
    })
  },
  async [SHARE_LINK_WISHLIST](ctx, { payload = {}, params = {}, wishlistId }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.wishlist.shareLink, wishlistId),
      params,
      payload,
    })
  },

  // Cart related apis

  async [ADD_PRODUCT_TO_CART](ctx, { payload = {}, params = {} }) {
    return await kpRequest({ ...kpEndpoint.cart.add, params, payload })
  },
  async [UPDATE_CART_NOTES](ctx, { payload = {}, params = {} }) {
    return await kpRequest({ ...kpEndpoint.cart.updateNotes, params, payload })
  },
  async [FETCH_CART_COUNT](ctx) {
    return kpRequest(kpEndpoint.cart.count).then(res => {
      ctx.commit(UPDATE_CART_ITEMS_COUNT, res.data.data.count)
    })
  },
  async [FETCH_CART_PRODUCTS](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.cart.get, params })
  },

  async [GET_PRODUCTS_BY_IDS](ctx, { payload }) {
    return await kpRequest({
      ...kpEndpoint.catalog.products.getProductsByIds,
      payload,
    })
  },

  async [CLEAR_CART](ctx, { payload }) {
    return await kpRequest({ ...kpEndpoint.cart.delete, payload })
  },
  async [REMOVE_PRODUCT_FROM_CART](ctx, { productId }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.cart.deleteProduct, productId),
    })
  },
  async [REMOVE_PRODUCT_FROM_CART_BY_CART_ID](ctx, { cartId, productId }) {
    return await kpRequest({
      ...kpParams(kpEndpoint.cart.deleteProductByCartId, cartId, productId),
    })
  },
  async [IMPORT_CART](ctx, { payload, params = {} }) {
    return await kpRequest({ ...kpEndpoint.cart.import, params, payload })
  },
  // Cart export by params with retailerId
  async [EXPORT_CART](ctx, { payload = {}, params = {} }) {
    return await kpRequest({
      ...kpEndpoint.downloads?.[payload.fileType],
      payload,
      params,
    })
  },
  async [EXPORT_CART_MULTIPLE](ctx, { payload = {} }) {
    if (payload.fileType === FILE_FORMATS.XLSX) {
      return await kpRequest({
        ...kpEndpoint.downloads.cartExportMultiple,
        payload,
      })
    }
    let endpointObj = kpEndpoint.downloads?.[payload.fileType]
    endpointObj = {
      ...endpointObj,
      url: `${endpointObj.url}/multiple`,
    }
    return await kpRequest({ ...endpointObj, payload })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

export function ucFirst(text) {
  return text.charAt(0)
    .toUpperCase() + text.slice(1)
}

export function showRelativeTime(inputTime) {
  if (typeof inputTime === 'string' || isNaN(parseInt(inputTime))) {
    inputTime = new Date(inputTime).getTime()
  }
  if (inputTime) {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const msPerMonth = msPerDay * 30
    const msPerYear = msPerDay * 365

    const elapsed = new Date().getTime() - inputTime

    if (elapsed < msPerMinute) {
      return `${Math.round(elapsed / 1000)} seconds ago`
    }
    if (elapsed < msPerHour) {
      return `${Math.round(elapsed / msPerMinute)} minutes ago`
    }
    if (elapsed < msPerDay) {
      return `${Math.round(elapsed / msPerHour)} hours ago`
    }
    if (elapsed < msPerMonth) {
      return `${Math.round(elapsed / msPerDay)} days ago`
    }
    if (elapsed < msPerYear) {
      return `${Math.round(elapsed / msPerMonth)} months ago`
    }

    return `${Math.round(elapsed / msPerYear)} years ago`
  }
  return ''
}
export function showRelativeDate(inputTime) {
  if (typeof inputTime === 'undefined') {
    return 0
  }
  if (typeof inputTime === 'string' || isNaN(parseInt(inputTime))) {
    inputTime = new Date(inputTime).getTime()
  }
  if (inputTime) {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const elapsed = new Date().getTime() - inputTime
    return Math.round(elapsed / msPerDay)
  }
}
export function getNumberofDays(inputTime) {
  if (typeof inputTime === 'undefined' || !inputTime) {
    return 10
  }
  if (inputTime) {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const elapsed = new Date().getTime() - inputTime
    return Math.round(elapsed / msPerDay)
  }
}

export function trimDescription(description, maxDescriptionLength) {
  // trim the string to the maximum length
  let trimmed = description.substr(0, maxDescriptionLength)
  // re-trim if we are in the middle of a word
  const minSpacing = Math.min(trimmed.length, trimmed.lastIndexOf(' '))
  if (minSpacing > 0) trimmed = trimmed.substr(0, minSpacing)
  return trimmed
}

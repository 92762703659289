import {
  kpRequest, kpEndpoint, kpParams,
} from '@/axios'
import {
  dispatchFetchProfile,
  dispatchGetCommonAuth,
} from '../helpers/store-helpers'

export const FETCH_BRANDS = 'fetchBrands'
export const FETCH_BRANDS_FOR_GUEST = 'fetchBrandsForGuest'
export const FETCH_BRAND_DETAIL = 'fetchBrandDetail'

// Subscription and schedule collection
export const GET_SCHEDULE = 'getScheduleForRetailer'
export const FOLLOW_OR_UNFOLLOW_BRAND = 'followOrUnFollowBrand'
export const CREATE_SCHEDULE = 'createSchedule'
export const DELETE_SCHEDULE = 'deleteSchedule'

const state = {
  brands: [],
  premiumBrands: [],
}
const getters = {
  brands(state) {
    return state.brands
  },
  premiumBrands(state) {
    return state.premiumBrands
  },
}
const mutations = {
  setBrands(state, payload) {
    state.brands = payload
  },
  setPremiumBrands(state, payload) {
    state.premiumBrands = payload
  },
}
const actions = {
  async [FETCH_BRANDS](context) {
    const profileData = await dispatchFetchProfile(context.dispatch)
    const params = {
      isDemo: !!profileData.isDemo,
    }
    return new Promise(resolve => {
      dispatchGetCommonAuth(context.dispatch, params)
        .then(res => {
          context.commit('setBrands', res.data.data.BRANDS)
          if (res.data.data && res.data.data.PREMIUMBRANDS && res.data.data.PREMIUMBRANDS.length > 0) {
            context.commit('setPremiumBrands', res.data.data.PREMIUMBRANDS)
          }
          return resolve(res.data.data)
        })
    })
  },
  async [FETCH_BRANDS_FOR_GUEST](context) {
    return new Promise(resolve => {
      kpRequest({ ...kpEndpoint.common.auth })
        .then(res => {
          context.commit('setBrands', res.data.data.BRANDS)
          return resolve(res.data.data.BRANDS)
        })
    })
  },
  async [FETCH_BRAND_DETAIL](ctx, brandId) {
    return await kpRequest({ ...kpParams(kpEndpoint.profile.brandProfile, brandId) })
  },
  // For Brands schedule get don't pass params
  async [GET_SCHEDULE](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.scheduleCollection.get, params })
  },
  async [FOLLOW_OR_UNFOLLOW_BRAND](ctx, collectionScheduleId) {
    return await kpRequest({ ...kpParams(kpEndpoint.scheduleCollection.subscribe, collectionScheduleId) })
  },
  async [CREATE_SCHEDULE](ctx, payload = {}) {
    return await kpRequest({ ...kpEndpoint.scheduleCollection.create, payload })
  },
  async [DELETE_SCHEDULE](ctx, collectionScheduleId) {
    return await kpRequest({ ...kpParams(kpEndpoint.scheduleCollection.delete, collectionScheduleId) })
  },

}

export default {
  state,
  actions,
  mutations,
  getters,
}

import { kpRequest, kpEndpoint, kpParams } from '@/axios'
import { apiToastError } from '@/@core/utils/toast'
import { dispatchFetchCartCount } from '@/store/helpers/store-helpers'

export const LOAD_CARTS = 'loadMultipleCarts'
export const SET_CARTS_DATA = 'setCartsData'
export const SET_CART_DATA = 'setCartData'
export const SET_IS_MULTIPLE_CART = 'setIsMultipleCart'
export const SET_IS_LOADING_CARTS = 'setIsLoadingCarts'
export const SET_IS_NO_CARTS_FOUND = 'setIsNoCartsFound'
export const DELETE_CARTS = 'deleteCarts'
export const UPDATE_CARTS = 'updateCarts'
export const REMOVE_PRODUCTS_FROM_CARTS = 'removeProductsFromCarts'
export const UPDATE_CART_ERRORS = 'updateCartHasErrors'
export const RESET_CART_ERRORS = 'resetCartErrors'
export const DELETE_PRODUCTS_BY_CART_ID = 'deleteProductsByCartId'
export const PUSH_EXPANDED_CARTS = 'pushExpandedCarts'
export const POP_EXPANDED_CARTS = 'popExpandedCarts'
export const RESET_EXPANDED_CARTS = 'resetExpandedCarts'

const state = {
  carts: [],
  cart: {},
  isMultipleCart: false,
  isLoadingCarts: false,
  isNoCartsFound: false,
  isCartHasErrors: 0,
  expandedCarts: [],
}
const getters = {
  getCarts(state) {
    return state.carts
  },
  getCart(state) {
    return state.cart
  },
}
const mutations = {
  [SET_CARTS_DATA](state, data) {
    state.carts = data
  },
  [SET_CART_DATA](state, data) {
    state.cart = data
  },
  [SET_IS_MULTIPLE_CART](state, data) {
    state.isMultipleCart = data
  },
  [SET_IS_LOADING_CARTS](state, data) {
    state.isLoadingCarts = data
  },
  [SET_IS_NO_CARTS_FOUND](state, data) {
    state.isNoCartsFound = data
  },
  [UPDATE_CART_ERRORS](state) {
    state.isCartHasErrors++
  },
  [RESET_CART_ERRORS](state) {
    state.isCartHasErrors = 0
  },
  [PUSH_EXPANDED_CARTS](state, cartId = '') {
    if (cartId && !state.expandedCarts.includes(cartId)) {
      state.expandedCarts.push(cartId)
    }
  },
  [POP_EXPANDED_CARTS](state, cartId = '') {
    if (cartId && state.expandedCarts.includes(cartId)) {
      state.expandedCarts = state.expandedCarts.filter(id => id !== cartId)
    }
  },
  [RESET_EXPANDED_CARTS](state) {
    state.expandedCarts = []
  },
}

const actions = {
  async [LOAD_CARTS](ctx) {
    const setLoading = isLoading => ctx.commit(SET_IS_LOADING_CARTS, isLoading)
    const setNoCartsFound = isNotFound => ctx.commit(SET_IS_NO_CARTS_FOUND, isNotFound)
    const processCarts = carts => {
      const cartsWithProducts = carts.filter(
        cart => cart.products.length > 0,
      )
      setNoCartsFound(!cartsWithProducts.length)
      ctx.commit(SET_IS_MULTIPLE_CART, true)
      ctx.commit(SET_CARTS_DATA, cartsWithProducts)
    }
    const processSingleCart = cart => {
      ctx.commit(SET_IS_MULTIPLE_CART, false)
      ctx.commit(SET_CART_DATA, cart)
      setNoCartsFound(false)
    }

    setLoading(true)
    try {
      const res = await kpRequest({ ...kpEndpoint.cart.get })
      const data = res?.data?.data

      if (Array.isArray(data)) {
        processCarts(data || [])
      } else if (data?._id) {
        processSingleCart(data)
      } else {
        setNoCartsFound(true)
      }
    } catch (err) {
      apiToastError(err)
      setNoCartsFound(true)
    } finally {
      setLoading(false)
      dispatchFetchCartCount(ctx.dispatch)
    }
  },
  async [UPDATE_CARTS](ctx) {
    const COLOR_KEY = 'color'

    const buildProductPayload = prod => {
      if (prod.isCarton) {
        return [
          {
            productId: prod._id,
            quantity: prod.quantity,
          },
        ]
      } else if (Array.isArray(prod.variants) && prod.variants.length > 0) {
        const payloadProductsItems = prod.variants.flatMap(item => Object.entries(item)
          .filter(([key]) => key !== COLOR_KEY)
          .map(([, value]) => ({
            sku: value.skuId,
            value: value.value || 0,
          })))

        return [
          {
            productId: prod._id,
            items: payloadProductsItems,
          },
        ]
      }
      return []
    }

    const buildCartPayload = cart => {
      const cartPayload = {
        cartId: cart._id,
        products: cart.products.flatMap(buildProductPayload),
      }

      if (cart.notes) {
        cartPayload.notes = cart.notes
      }

      return cartPayload
    }

    const payloads = ctx.getters.getCarts.map(buildCartPayload)

    return await kpRequest({ ...kpEndpoint.cart.add, payload: payloads })
  },
  async [DELETE_CARTS](ctx, storeIds = []) {
    const payload = { storeIds }
    return await kpRequest({ ...kpEndpoint.cart.deleteAll, payload })
  },
  async [DELETE_PRODUCTS_BY_CART_ID](ctx, { cartId = '', productIds }) {
    return await kpRequest({ ...kpParams(kpEndpoint.cart.deleteProductsByCartId, cartId), payload: { productIds } })
  },
  [REMOVE_PRODUCTS_FROM_CARTS](ctx, { cartId = '', productIds = [] }) {
    let isCartHasNoProducts = false
    ctx.state.carts.forEach(c => {
      if (c._id === cartId) {
        c.products = c.products.filter(p => !productIds.includes(p._id))
      }
      if (!c?.products?.length) {
        isCartHasNoProducts = true
      }
    })
    if (isCartHasNoProducts) {
      ctx.dispatch(LOAD_CARTS)
    }
  },
}

export default {
  actions,
  state,
  getters,
  mutations,
}

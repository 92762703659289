import Vue from 'vue'
import iframeResize from 'iframe-resizer/js/iframeResizer'

Vue.directive('click-outside', {
  bind(element, binding, vnode) {
    element.clickOutsideEvent = function listen(event) { //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
        // binding.value(); run the arg
      }
    }
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind(element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  },
})

// Directive to handle iframe resize
Vue.directive('resize', {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind(el) {
    el.iFrameResizer.removeListeners()
  },
})

// import useJwt from '@/auth/jwt/useJwt'

import { constants } from '@kingpin-global/kingpin-utils-frontend'

const { ROLES } = constants

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!localStorage.getItem('cTok')

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = role => {
    if (role === ROLES.GUEST) return { name: 'explore' }
    return { name: 'dashboard' } // Default in case of no role specified
}
/* if (userRole === 'retailer') return {name: 'explore'}
if (userRole === 'brand') return {name: 'collections'}
return {name: 'auth-login'} */

export const REDIRECT_ROUTE_KEY = 'redirectUrl'

export const setRedirectRouteData = (data = null) => {
    if (data) {
        localStorage.setItem(REDIRECT_ROUTE_KEY, JSON.stringify(data))
    } else {
        localStorage.removeItem(REDIRECT_ROUTE_KEY)
    }
}

export const getRedirectRouteData = () => JSON.parse(localStorage.getItem(REDIRECT_ROUTE_KEY))

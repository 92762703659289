import { constants } from '@kingpin-global/kingpin-utils-frontend'

const { ROLES } = constants

/* eslint-disable */
const ID_TOKEN_KEY = 'cTok'
export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY)
}
export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token)
}
export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY)
    clearRole()
    clearEntityType()
    clearUserData()
    clearAdmin()
    localStorage.clear()
}

const ID_ROLE_KEY = 'cRole'
const ID_ENTITY_TYPE_KEY = 'cEntityType'

export const getRole = () => {
    return window.localStorage.getItem(ID_ROLE_KEY)
}
export const saveRole = role => {
    window.localStorage.setItem(ID_ROLE_KEY, role)
}
export const getEntityType = () => {
    return window.localStorage.getItem(ID_ENTITY_TYPE_KEY)
}
export const saveEntityType = entityType => {
    window.localStorage.setItem(ID_ENTITY_TYPE_KEY, entityType)
}
export const clearRole = () => {
    window.localStorage.removeItem(ID_ROLE_KEY)
}
export const clearEntityType = () => {
    window.localStorage.removeItem(ID_ENTITY_TYPE_KEY)
}

const ID_USER_DATA = 'userData'
export const getUserData = () => {
    return JSON.parse(window.localStorage.getItem(ID_USER_DATA)) || {}
}

export const saveAbility = () => {
    const userData = getUserData()
    const role = getEntityType()
    userData.ability = [
        {
            action: 'read',
            subject: 'Dashboard'
        },
        {
            action: 'read',
            subject: 'Order'
        },
        {
            action: 'read',
            subject: 'Shipment'
        },
        {
            action: 'read',
            subject: 'Transactions'
        },
        {
            action: 'read',
            subject: 'Wallet'
        },
        {
            action: 'read',
            subject: 'Profile'
        },
        {
            action: 'read',
            subject: 'Referrals'
        },
        {
            action: 'read',
            subject: 'Auth'
        },
        {
            action: 'read',
            subject: 'Shop'
        },
        {
            action: 'read',
            subject: 'Help'
        }
    ]
    if (role === ROLES.RETAILER) {
        userData.ability.push({
            action: 'manage',
            subject: 'Retailer'
        })
    }
    else if (role === ROLES.BRAND) {
        userData.ability.push({
            action: 'manage',
            subject: 'Brand'
        })
    }
    if (role === ROLES.GUEST) {
        userData.ability = [
        {
            action: 'manage',
            subject: 'Guest'
        },
        {
            action: 'read',
            subject: 'Auth'
        },
        {
            action: 'read',
            subject: 'Shop'
        },
        {
            action: 'read',
            subject: 'Help'
        }
    ]
    }
    saveUserData(userData)
    return userData.ability
}
export const saveUserData = userData => {
    window.localStorage.setItem(ID_USER_DATA, JSON.stringify(userData))
}
const ID_SWITCHED_USER_ID = 'switchedUserId'
export const saveSwitchedUserId = userId => {
    window.localStorage.setItem(ID_SWITCHED_USER_ID, userId)
}
export const getSwitchedUserId = () => {
    return window.localStorage.getItem(ID_SWITCHED_USER_ID)
}
const clearUserData = () => {
    window.localStorage.removeItem(ID_USER_DATA)
}

const IS_ADMIN = 'isAdmin'
export const getAdmin = () => {
    return window.localStorage.getItem(IS_ADMIN)  
}
export const saveAdmin = () => {
    window.localStorage.setItem(IS_ADMIN, true)
}
export const clearAdmin = () => {
    window.localStorage.removeItem(IS_ADMIN)
}

export default {
    getToken,
    saveToken,
    destroyToken,
    getRole,
    saveRole,
    clearRole,
    getEntityType,
    saveEntityType,
    clearEntityType,
    getUserData,
    saveUserData,
    saveAbility,
    getAdmin,
    saveAdmin,
    clearAdmin,
}

import axios from 'axios'
import { Settings } from '@/Settings'

let _auth = null

function auth() {
  if (!_auth) {
    _auth = axios.create({
      baseURL: Settings.getConfig('VUE_APP_BACKEND_URL'),
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    _auth.interceptors.response.use(undefined, err => new Promise((() => {
      throw err
    })))
  }
  return _auth
}

const authRequest = (obj = {}) => {
  const requestPayload = {
    ...obj,
    data: obj?.payload || {},
  }
  return auth()(requestPayload)
}

export default authRequest

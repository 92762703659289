import { kpRequest, kpEndpoint } from '@/axios'

// Broadcast mails
export const GET_BROADCAST_MAIL_GROUPS = 'getBroadcastMailGroups'
export const SEND_BROADCAST_MAIL = 'sendBroadcastMail'
export const GET_BROADCAST_MAILS = 'getBroadcastMails'

// Schedule meeting
export const SCHEDULE_MEETING = 'scheduleMeeting'

export const SHOW_WISHLIST_NAV_INDICATOR = 'showWishlistNavIndicator'
export const RESET_WISHLIST_NAV_INDICATOR = 'resetWishlistNavIndicator'
export const SHOW_DOWNLOADS_NAV_INDICATOR = 'setDownloadsNavIndicator'
export const RESET_DOWNLOADS_NAV_INDICATOR = 'resetDownloadsNavIndicator'
export const SHOW_SALES_ANALYTICS_NAV_INDICATOR = 'showSalesAnalyticsNavIndicator'
export const RESET_SALES_ANALYTICS_NAV_INDICATOR = 'resetSalesAnalyticsNavIndicator'

let debounceValue = null
export const notificationDuration = 60000

export const state = {
    wishlistsNavIndicator: false,
    downloadsNavIndicator: false,
    SalesAnalyticsNavIndicator: false,
}

export const mutations = {
    [SHOW_WISHLIST_NAV_INDICATOR](state) {
        if (state.wishlistsNavIndicator && debounceValue) {
            clearTimeout(debounceValue)
        }
        state.wishlistsNavIndicator = true
        debounceValue = setTimeout(() => {
            state.wishlistsNavIndicator = false
        }, notificationDuration)
    },
    [SHOW_DOWNLOADS_NAV_INDICATOR](state) {
        if (state.downloadsNavIndicator && debounceValue) {
            clearTimeout(debounceValue)
        }
        state.downloadsNavIndicator = true
        debounceValue = setTimeout(() => {
            state.downloadsNavIndicator = false
        }, notificationDuration)
    },
    [SHOW_SALES_ANALYTICS_NAV_INDICATOR](state) {
        if (state.SalesAnalyticsNavIndicator && debounceValue) {
            clearTimeout(debounceValue)
        }
        state.SalesAnalyticsNavIndicator = true
        debounceValue = setTimeout(() => {
            state.SalesAnalyticsNavIndicator = false
        }, notificationDuration)
    },
    [RESET_WISHLIST_NAV_INDICATOR](state) {
        state.wishlistsNavIndicator = false
    },
    [RESET_DOWNLOADS_NAV_INDICATOR](state) {
        state.downloadsNavIndicator = false
    },
    [RESET_SALES_ANALYTICS_NAV_INDICATOR](state) {
        state.SalesAnalyticsNavIndicator = false
    },
}

export const actions = {
    async [GET_BROADCAST_MAIL_GROUPS]() {
        return await kpRequest({ ...kpEndpoint.notifications.emailGroups })
    },
    async [SEND_BROADCAST_MAIL](ctx, payload) {
        return await kpRequest({ ...kpEndpoint.notifications.post, payload })
    },
    async [GET_BROADCAST_MAILS](ctx, params) {
        return await kpRequest({ ...kpEndpoint.notifications.get, params })
    },
    async [SCHEDULE_MEETING](ctx, payload) {
        return await kpRequest({ ...kpEndpoint.profile.scheduleMeeting, payload })
    },
}

export default {
    actions,
    state,
    mutations,
}

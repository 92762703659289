import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export const SELECT_OR_REMOVE_SELETION = 'selectOrRemoveSelection'
export const SELECT_ALL_SELECTIONS = 'selectAllSelections'
export const RESET_SELECTED_SELECTIONS = 'resetSelectedSelections'
export const MULTI_SELECT_MODAL_ID = 'multiSelectModalId'

export const SET_SELECTED_RETAILER = 'setSelectedRetailer'

export const shareSelectionsErrorModalId = 'modal-share-multiple-selection-fails'

const { SHARE_MULTIPLE_SELECTIONS } = constants.TRACKS.ACTIONS

export const state = {
    selectedSelections: [],
    selectedRetailer: null,
}

export const getters = {
    getSelectedSelections: state => state.selectedSelections,
    getSelectedRetailer: state => state.selectedRetailer,
}

export const mutations = {
    [SELECT_OR_REMOVE_SELETION](state, selection = null) {
        if (!selection) {
            return
        }
        const selectionId = selection._id
        if (state.selectedSelections.includes(selectionId)) {
            state.selectedSelections = state.selectedSelections.filter(item => item !== selectionId)
            analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_DESELECTS_SELECTION_BY_CHECKBOX, { selection })
        } else {
            state.selectedSelections.push(selectionId)
            analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_SELECTS_SELECTION_BY_CHECKBOX, { selection })
        }
    },
    [SELECT_ALL_SELECTIONS](state, selectionIds) {
        state.selectedSelections = selectionIds
    },
    [RESET_SELECTED_SELECTIONS](state) {
        state.selectedSelections = []
    },
    [SET_SELECTED_RETAILER](state, retailer) {
        state.selectedRetailer = retailer
    },
}

export default {
    state,
    getters,
    mutations,
}

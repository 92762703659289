import {
 kpRequest, kpEndpoint, kpParams,
} from '@/axios'

export const GET_CREDIT_APPLICATION = 'getCreditApplication'
export const CREATE_NEW_CREDIT_APPLICATION = 'createNewCreditApplication'
export const VERIFY_CREDIT_APPLICATION = 'CreditApplication'

// Transactions

export const GET_TRANSACTIONS = 'getTransactions'
export const GET_REWARDS = 'getRewards'
export const GET_SAVINGS = 'getSavings'
export const PAY_TRANSACTION = 'payTransaction'

// Updated by brand for their retailers in My Retailers
export const UPDATE_WALLET_RULE = 'updateWalletRule'

const actions = {
  async [GET_CREDIT_APPLICATION]() {
    return await kpRequest({ ...kpEndpoint.creditDebit.creditApplication.get })
  },
  async [CREATE_NEW_CREDIT_APPLICATION](ctx, payload) {
    return await kpRequest({ ...kpEndpoint.creditDebit.creditApplication.get, payload })
  },
  [VERIFY_CREDIT_APPLICATION]() {
    return new Promise((resolve, reject) => {
      kpRequest({ ...kpEndpoint.creditDebit.creditApplication.verify })
        .then(res => {
          resolve(res.data.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  async [UPDATE_WALLET_RULE](ctx, { walletRuleId = '', payload = {} }) {
    await kpRequest({ ...kpParams(kpEndpoint.wallet.update, walletRuleId), payload })
  },
  // Transactions
  async [GET_TRANSACTIONS](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.transaction.get, params })
  },
  async [GET_REWARDS]() {
    return await kpRequest({ ...kpEndpoint.transaction.rewards })
  },
  async [GET_SAVINGS]() {
    return await kpRequest({ ...kpEndpoint.transaction.savings })
  },
  async [PAY_TRANSACTION](ctx, { transaction, payload }) {
    return await kpRequest(
        {
          ...kpParams(kpEndpoint.transaction.pay, transaction),
          payload,
       },
      )
  },
}

export default {
  actions,
}

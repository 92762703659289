import axios from 'axios'

export class Settings {
    static #settings = undefined;

    static #mixpanelToken = undefined;

    static #mixpanelEnabled = undefined;

    static async load() {
        if (!Settings.#settings) {
            const response = await axios.get('/settings.json')
            Settings.#settings = response.data
        }
        this.#mixpanelToken = Settings.getConfig('VUE_APP_MIXPANEL_TOKEN')
        this.#mixpanelEnabled = Settings.getConfig('VUE_APP_MIXPANEL_ENABLED') === 'true' && Settings.#mixpanelToken
    }

    static getConfig(key) {
        if (Settings.#settings) {
            const value = Settings.#settings[key]
            if (value == null) {
                console.error(`settings.${ key } is not set`)
            }
            return value
        } else {
            throw new Error('settings are not yet loaded')
        }
    }

    static mixpanelEnabled() {
        if (Settings.#settings) {
            return Settings.#mixpanelEnabled
        } else {
            throw new Error('settings are not yet loaded')
        }
    }

    static mixpanelToken() {
        if (Settings.#settings) {
            return Settings.#mixpanelToken
        } else {
            throw new Error('settings are not yet loaded')
        }
    }
}

<template>
  <span>
    <validation-icon
      :id="id"
      :show="show"
      :valid="errors.length > 0 ? false : true"
    />
    <b-tooltip
      v-if="errors.length"
      :target="id"
      variant="danger"
      placement="bottomleft"
    >
      {{ errors[0] }}
    </b-tooltip>
  </span>
</template>
<script>
import { BTooltip } from 'bootstrap-vue'
import ValidationIcon from '@/@core/assets/svg-components/ValidationIcon.vue'

export default {
  name: 'ValidationIconWithTooltip',
  components: {
    ValidationIcon,
    BTooltip,
  },
  props: {
    id: {
      type: String,
      default: 'ValidationIconWithTooltip',
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

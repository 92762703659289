import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import {
  downloadCSV, downloadPDF, downloadXlsx, downloadZip,
} from '@/@core/utils/utils'
import { kpRequest, kpParams, kpEndpoint } from '@/axios'
import { FILE_FORMATS } from '@/constants'

export const GET_COMMON_BASE = 'getCommonBase'
export const GET_COMMON_AUTH = 'getCommonAuth'

export const DOWNLOAD_FILE = 'downloadFile'
export const UPLOAD_DOCUMENTS = 'uploadDocuments'

export const SET_COMMON_DATA = 'setCommonData'
export const INIT_COMMON_DATA = 'initCommonData'

export const GET_DOWNLOADS_LIST = 'downloadsList'
export const GET_COUNTRIES = 'getCountries'

const state = {
  commonData: null,
}
const getters = {
  commonData(state) {
    return state.commonData
  },
}
const mutations = {
  [SET_COMMON_DATA](state, data) {
    state.commonData = data
  },
  [INIT_COMMON_DATA]() {
    this.dispatch(GET_COMMON_BASE)
  },
}

const actions = {
  async [GET_COMMON_BASE](ctx) {
    if (ctx.getters.commonData) {
      return ctx.getters.commonData
    }
    return await kpRequest({ ...kpEndpoint.common.base })
      .then(res => {
        ctx.commit(SET_COMMON_DATA, res.data.data)
        return res.data.data
      })
  },
  async [GET_COUNTRIES]() {
    return await kpRequest({ ...kpEndpoint.common.countries })
  },
  async [GET_COMMON_AUTH](ctx, req = {}) {
    const params = req?.params || {}
    return await kpRequest({ ...kpEndpoint.common.auth, params })
  },
  [DOWNLOAD_FILE](
    ctx,
    {
      url = '',
      params = {},
      fileName = '',
      successMessage = '',
      fileFormat = FILE_FORMATS.CSV,
      headers = {},
    },
  ) {
    return new Promise((resolve, reject) => {
      kpRequest({ ...url, params, ...headers })
        .then(res => {
          switch (fileFormat) {
            case FILE_FORMATS.CSV:
              downloadCSV({
                csv: res.data,
                fileName,
              })
              break
            case FILE_FORMATS.XLSX:
              downloadXlsx({
                xlsx: res.data,
                fileName,
              })
              break
            case FILE_FORMATS.PDF:
              downloadPDF({
                pdf: res.data,
                fileName,
              })
              break
            case FILE_FORMATS.ZIP:
              downloadZip({
                zip: res.data,
                fileName,
              })
              break
            default:
              break
          }
          if (successMessage) {
            apiToastSuccess(successMessage || 'Downloaded successfully')
          }
          resolve(res)
        })
        .catch(err => {
          apiToastError(err)
          reject(err)
        })
    })
  },

  [UPLOAD_DOCUMENTS](ctx, { fileType, file, loaderObj = {} }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', file)
      kpRequest({
        ...kpParams(kpEndpoint.files.fileUpload, fileType),
        payload: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...loaderObj,
      })
        .then(res => {
          resolve(res.data.data?.location)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  async [GET_DOWNLOADS_LIST](ctx, params = {}) {
    return await kpRequest({ ...kpEndpoint.downloads.getList, params })
  },
}

export default {
  actions,
  state,
  getters,
  mutations,
}

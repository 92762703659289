import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'

import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect' // eslint-disable-line
import { Settings } from '@/Settings'
import mixpanelTracks from './mixpanel-tracks'

const { ORDER_PRODUCT_STATUS_COLOR_CODE } = utils

const { COLLECTION_STATUS, KP_MONTH_FORMAT } = constants

const ORDER_PRODUCT_SHIPMENT_STATUS = {
  ...constants.ORDER_PRODUCT_SHIPMENT_STATUS,
  // one more constant for selected products
  SELECTED_BY_USER: {
    LABEL: 'selectedByUser',
    TEXT: constants.ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.TEXT,
  },
}
export const PRODUCTS_LIMIT = 20
export const FILE_FORMATS = {
  CSV: 'csv',
  ZIP: 'zip',
  PDF: 'pdf',
  XLSX: 'xlsx',
  PPTX: 'pptx',
}

export default {
  ORDER_PRODUCT_STATUS_COLOR_CODE: {
    ...ORDER_PRODUCT_STATUS_COLOR_CODE().FRONTEND,
    [ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL]: '#16acd9',
  },
  ORDER_PRODUCT_SHIPMENT_STATUS,

  ORDER_STATUS: constants.ORDER_STATUS,
  CONNECTION_REQUEST_STATUS: constants.CONNECTION_REQUEST_STATUS,
  PAYMENT_METHODS: constants.PAYMENT_METHODS,
  DOWNLOAD_OPTIONS: {
    ORDER: [
      { text: 'CSV', value: 'csv' },
      // { text: 'Images(Zip)', value: 'zip' },
    ],
    WISHLIST: [
      { text: 'PDF', value: 'pdf', fileType: FILE_FORMATS.PDF },
      { text: 'Excel', value: 'xlsx', fileType: FILE_FORMATS.XLSX },
      {
        text: 'Order Form',
        value: 'sku',
        fileType: FILE_FORMATS.XLSX,
        params: { type: 'sku' },
      },
      {
        text: 'Order Form (without images)',
        value: 'sku_no_images',
        fileType: FILE_FORMATS.XLSX,
        params: { noImages: true, type: 'sku' },
      },
      { text: 'PPTX', value: 'pptx', fileType: FILE_FORMATS.PPTX },
    ],
    SHIPMENT: [
      { text: 'CSV', value: 'csv' },
      // { text: 'Images(Zip)', value: 'zip' },
    ],
    CART: [
      { text: 'Excel', value: 'xlsx', fileType: FILE_FORMATS.XLSX },
      { text: 'PDF', value: 'pdf', fileType: FILE_FORMATS.PDF },
      { text: 'PPTX', value: 'pptx', fileType: FILE_FORMATS.PPTX },
    ],
    CATALOG: {
      XLSX: 'Download Excel',
      // CSV: 'csv',
    },
    WISHLIST_EXPORT: {
      XLSX: 'Download Excel',
      PDF: 'pdf',
    },
  },
  TRACKS: mixpanelTracks,
}

export const DOWNLOAD_TYPES = {
  ORDER_EXPORT: {
    LABEL: 'Order Export',
    VALUE: 'orderExport',
  },
  COLLECTION_EXPORT: {
    LABEL: 'Collection Export',
    VALUE: 'collectionExport',
  },
  WISHLIST_EXPORT: {
    LABEL: 'Wishlist Export',
    VALUE: 'wishlistExport',
  },
  SELECTION_EXPORT: {
    LABEL: 'Selection Export',
    VALUE: 'selectionExport',
  },
  CART_EXPORT: {
    LABEL: 'Cart Export',
    VALUE: 'cartExport',
  },
  MULTIPLE_CART_EXPORT: {
    LABEL: 'Multiple Cart Export',
    VALUE: 'multipleCartExport',
  },
}

export const DOWNLOAD_FILE_STATUS = {
  PROCESSING: {
    LABEL: 'Processing',
    VALUE: 'processing',
  },
  READY_TO_DOWNLOAD: {
    LABEL: 'Ready to Download',
    VALUE: 'readyToDownload',
  },
  ERROR: {
    LABEL: 'Error',
    VALUE: 'error',
  },
}

export const exProfileData = {
  banner: 1,
  companyProfile: 1,
  departments: 1,
  others: 1,
  logo: 1,
  storeDescription: 1,
  token: 1,
  __v: 1,
  _id: 1,
}

export const tooltipData = {
  retailer: {
    general: 'Fill in you general information to the best of your ability so our brands can learn more about you and your store',
    segmentation: 'Select preferred departments to receive most relevant information from your favourite brands',
    documents: 'It is mandatory to upload your Commercial License and VAT Registration details in order to place orders',
    addresses: 'Fill in billing and shipping addresses so you can start placing orders',
    teammates: 'Invite and collaborate with your teammates and improve your workflows',
    referrals: 'Send an invites to your favourite brands and win credits to spend on their latest collections',
    firstorder: 'Place your first order and experience the future of buying from international brands',
  },
  brand: {
    general: 'Fill in your general information to the best of your ability in so our retail partners can learn more about your business',
    segmentation: 'Select your applicable departments and price ranges to segment your Brand on Kingpin.',
    documents: 'It is mandatory to upload your Commercial License & VAT Registration in order to begin receiving orders',
    addresses: 'Here is where you can provide both Billing & Shipping Address details for your business',
    teammates: 'Invite and collaborate with your teammates and improve your workflows',
    firstorder: 'Receive your first Order and experience the future of International wholesale trade.',
    referrals: 'Send an invites to retailers that approach you from the region and get special terms when they order from you',
    social: 'Add your social profiles for more info about your company',
    firstCollection: 'Allow retailers to discover your brand through beautifully curated catalogues. Collections can be made for products that are in-stock or for collecting pre-orders due for later delivery',
  },
}

export const checkListTitle = {
  general: 'Complete general information',
  segmentation: 'Choose your segment',
  documents: 'Upload your documents',
  addresses: 'Complete an address',
  teammates: 'Invite your teammates',
  firstCollection: 'Create your first collection',
  retailerFirstOrder: 'Place an Order',
  brandFirstOrder: 'Receive an Order',
  brandReferrals: 'Invite retailer to join and win credits!',
  retailerReferrals: 'Invite brand to join in and win credits!',
}

export const TOOLKIT_PAGE = {
  WELCOME_TO_KINGPIN: 'welcomeToKingpin',
  PROFILE: 'profile',
  BRANDS_EXPLORE: 'brandsExplore',
  BRAND_PROFILE: 'brandProfile',
  SHOP_PRODUCTS: 'shopProducts',
  WISHLIST: 'wishlist',
  CHECKOUT: 'checkout',
  ORDERS: 'orders',
  TRANSACTIONS: 'transactions',
  DASHBOARD: 'dashboard',
  BRAND_LANDING_PAGE: 'brandLandingPage',
  COLLECTIONS: 'collections',
  COLLECTION_EDIT: 'collectionEdit',
  REFERRALS: 'referrals',
  WALLET: 'wallet',
}

export const HELP_URL = () => Settings.getConfig('VUE_APP_HELP_URL')

export const wishlistAction = {
  ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
  REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST',
  REMOVE_IN_WISHLIST: 'REMOVE_IN_WISHLIST', //   It referes removing function from wishlist page
  ADD_BY_BRAND: 'ADD_BY_BRAND',
}

export const FILE_DOWNLOAD_FORMATS = {
  ZIP: {
    text: 'Images(zip)',
    value: 'zip',
  },
  XLSX_STYLE: {
    text: 'Excel - Style wise',
    value: 'style',
  },
  XLSX_SKU: {
    text: 'Excel - SKU wise',
    value: 'sku',
  },
  PDF: {
    text: 'Invoice',
    value: 'pdf',
  },
  ORDER_DETAILS_PDF: {
    text: 'Order Confirmation',
    value: 'odPdf',
  },
  SHIPMENT_DETAILS_PDF: {
    text: 'Shipment Details',
    value: 'sdPdf',
  },
}

export const EXPORT_ORDERS_OPTIONS = {
  ORDERS_LIST: {
    text: 'Export Orders',
    value: 'list',
  },
  PRODUCTION_FILE: {
    text: 'Export Consolidation File',
    value: 'productionFile',
  },
}

export const SAMPLE_PRODUCT_SHEET = 'SampleProductSheet'

export const MARKUP_FILTER_OPTIONS = {
  'Under 2.5': {
    UPPER_LIMIT: 2.5,
    LOWER_LIMIT: 0,
  },
  '2.5 - 2.8': {
    UPPER_LIMIT: 2.8,
    LOWER_LIMIT: 2.5,
  },
  '2.8 - 3.1': {
    UPPER_LIMIT: 3.1,
    LOWER_LIMIT: 2.8,
  },
  '3.1+': {
    UPPER_LIMIT: 100,
    LOWER_LIMIT: 3.1,
  },
}

export const PRODUCTS_VIEW = {
  COMPACT_GRID_VIEW: 'compact-grid-view',
  GRID_VIEW: 'grid-view',
}

export const PRODUCTS_ERROR_MESSAGE = 'Your collection upload file contains errors. We have sent the review to your email. Please fix the errors and upload the collection file again.'

export const NAV_ITEM_ID = {
  DASHBOARD: 'dashboard',
  WISHLIST: 'wishlist',
  CHECKOUT: 'checkout',
  EXPLORE: 'explore',
  COLLECTIONS: 'collections',
  ORDERS: 'orders',
  SHIPMENTS: 'shipments',
  CONNECTIONS: 'connections',
  SELECTIONS: 'selections',
  PROFILE: 'profile',
  MESSAGES: 'messages',
  ANALYTICS: 'analytics',
  REFERRALS: 'referrals',
  DOWNLOADS: 'downloads',
  HELP: 'help',
  KNOWLEDGE_CENTER: 'knowledge_center',
  KINGPIN_ACADEMY: 'kingpin_academy',
  SALES_ANALYTICS: 'sales_analytics',
}

export const FILE_TYPES = constants.FILE_TYPES

export const TRANSACTION_PAY_LIMIT = 15000

export const UPLOAD_COLLECTION_LOGO_FILE_TYPES = ['jpg', 'png', 'jpeg', 'svg']
export const UPLOAD_CATALOG_FILE_TYPES = ['xlsx', 'zip']

export const PORTAL_LINK = 'https://www.kingpin.global/'

export const CREDIT_APPLICATION_UPPER_LIMIT = 30000

export const VUE_EDITOR_DEFAULT_EDIT_OPTIONS = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ['bold', 'italic', 'underline', 'strike'],
  [
    { align: '' },
    { align: 'center' },
    { align: 'right' },
    { align: 'justify' },
  ],
  ['blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ color: [] }, { background: [] }],
  ['link'],
]

export const COLLECTION_STATUS_TOOLTIP = {
[COLLECTION_STATUS.NEW.VALUE]: 'Collection is created, add products to proceed to the next step',
[COLLECTION_STATUS.IN_REVIEW.VALUE]: 'Our team is reviewing the catalog and will approve it or reach you shortly',
[COLLECTION_STATUS.LIVE.VALUE]: 'Collection is published and available for placing orders',
[COLLECTION_STATUS.EXPIRED.VALUE]: 'The order deadline for this collection has passed',
}

export const IMAGE_COMPRESSER = {
  size: 4, // the max size in MB, defaults to 2MB
  quality: 0.75, // the quality of the image, max is 1,
  maxWidth: 1920,
  maxHeight: 1920,
  resize: true,
  rotate: false,
}

export const wholesalePrices = [
  'Under $25',
  '$26 - $50',
  '$51 - $100',
  '$101 - $250',
  '$250+',
]

export const retailPrices = [
  'Under $25',
  '$26 - $100',
  '$101 - $250',
  '$251 - $500',
  '$500+',
]

export const TABS = {
  ALL: 'all',
  MARKETPLACE: 'marketplace',
  DIRECT: 'direct',
}

export const API_METHODS = {
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  GET: 'get',
  DELETE: 'delete',
}

export const htmlToPdfOptions = {
    margin: 1,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
}

export const marketplaceAccessDeniedMessage = "You can't access the marketplace"

export const CONNECTION_TABS = constants.BRAND_ACCESS

export const VUE_FLATPICKR_MONTH_CONFIG = {
  plugins: [
    new MonthSelectPlugin({
      dateFormat: KP_MONTH_FORMAT.CONFIG,
      altFormat: KP_MONTH_FORMAT.CONFIG,
      shorthand: true,
    }),
  ],
}
// Used for concating date in Requested Delivery Date and Expected Delivery Date
export const MONTH_START_DATE = '01/'

export const CART_PRODUCTS_SEARCH_FIELDS = [
  'name',
  'department',
  'category',
  'subCategory',
  'uniqueId',
  'styleCode',
]

export const CART_PRODUCTS_SEARCH_ITEMS_FIELDS = ['color', 'colorFamily', 'sku']

export const USER_ROLES = {
  ENTITYADMIN: 'entityAdmin',
  SALESMANAGER: 'salesManager',
  SALESREPRESENTATIVE: 'salesRepresentative',
  KINGPINADMIN: 'kingpinAdmin',
}

export const USER_ROLES_LABEL = {
  [USER_ROLES.ENTITYADMIN]: 'Entity Admin',
  [USER_ROLES.SALESMANAGER]: 'Sales Manager',
  [USER_ROLES.SALESREPRESENTATIVE]: 'Sales Representative',
  [USER_ROLES.KINGPINADMIN]: 'Kingpin Admin',
}

const CURRENCY_CONVERSIONS = {
  AED: {
    SYMBOL: 'AED',
    LABEL: 'AED',
    AED: 1,
    GBP: 0.223052268,
    USD: 0.280427388,
    EUR: 0.2602225947,
    AUD: 0.416367612,
    CAD: 0.416367612,
    SAR: 1.02052,
    QAR: 0.9937,
    KWT: 0.0833425,
    BAH: 0.1079,
    OMR: 0.1089,
  },
  GBP: {
    SYMBOL: 'GBP',
    LABEL: 'GBP',
    AED: 4.757221513,
    GBP: 1,
    USD: 1.29519925,
    EUR: 1.201880144,
    AUD: 1.923532786,
    CAD: 1.726656024,
    SAR: 5.1259466,
    QAR: 4.726491,
    KWT: 0.396426,
    BAH: 0.484116,
    OMR: 0.488253,
  },
  USD: {
    SYMBOL: 'USD',
    LABEL: 'USD',
    AED: 3.78315395,
    GBP: 0.8195504,
    USD: 1,
    EUR: 0.9557885,
    AUD: 1.52967875,
    CAD: 1.3731136,
    SAR: 3.8595,
    QAR: 3.7511,
    KWT: 0.31484,
    BAH: 0.384,
    OMR: 0.387,
  },
  EUR: {
    SYMBOL: 'EUR',
    LABEL: 'EUR',
    AED: 4.076745611,
    GBP: 0.8831117,
    USD: 1.10993315,
    EUR: 1,
    AUD: 1.64882915,
    CAD: 1.4829528,
    SAR: 4.134,
    QAR: 4.023,
    KWT: 0.337,
    BAH: 0.411,
    OMR: 0.414,
  },
  AUD: {
    SYMBOL: 'AUD',
    LABEL: 'AUD',
    AED: 2.548010932,
    GBP: 0.5519794877,
    USD: 0.69372045,
    EUR: 0.6437378915,
    AUD: 1,
    CAD: 0.926441225,
    SAR: 2.616,
    QAR: 2.547,
    KWT: 0.213,
    BAH: 0.26,
    OMR: 0.262,
  },
  CAD: {
    SYMBOL: 'CAD',
    LABEL: 'CAD',
    AED: 2.83287492,
    GBP: 0.6133547,
    USD: 0.77261845,
    EUR: 0.71541225,
    AUD: 1.1451643,
    CAD: 1,
    SAR: 2.824,
    QAR: 2.747,
    KWT: 0.23,
    BAH: 0.282,
    OMR: 0.284,
  },
  SAR: {
    SYMBOL: 'SAR',
    LABEL: 'SAR',
    AED: 0.979878,
    GBP: 0.194952,
    USD: 0.259119,
    EUR: 0.241945,
    AUD: 0.382365,
    CAD: 0.354226,
    SAR: 1,
    QAR: 0.974,
    KWT: 0.0815,
    BAH: 0.1,
    OMR: 0.101,
  },
  QAR: {
    SYMBOL: 'QAR',
    LABEL: 'QAR',
    AED: 1.006291,
    GBP: 0.211497,
    USD: 0.266671,
    EUR: 0.24902,
    AUD: 0.39334,
    CAD: 0.364036,
    SAR: 1.0265,
    QAR: 1,
    KWT: 0.0837,
    BAH: 0.103,
    OMR: 0.104,
  },
  KWT: {
    SYMBOL: 'KWT',
    LABEL: 'KWT',
    AED: 11.99,
    GBP: 2.522,
    USD: 3.176,
    EUR: 2.97,
    AUD: 4.685,
    CAD: 4.337,
    SAR: 12.26,
    QAR: 11.95,
    KWT: 1,
    BAH: 1.23,
    OMR: 1.25,
  },
  BAH: {
    SYMBOL: 'BAH',
    LABEL: 'BAH',
    AED: 9.27,
    GBP: 1.95,
    USD: 2.45,
    EUR: 2.29,
    AUD: 3.62,
    CAD: 3.35,
    SAR: 9.48,
    QAR: 9.24,
    KWT: 0.812,
    BAH: 1,
    OMR: 1.02,
  },
  OMR: {
    SYMBOL: 'OMR',
    LABEL: 'OMR',
    AED: 9.19,
    GBP: 1.93,
    USD: 2.43,
    EUR: 2.27,
    AUD: 3.58,
    CAD: 3.32,
    SAR: 9.4,
    QAR: 9.17,
    KWT: 0.801,
    BAH: 0.98,
    OMR: 1,
  },
}

export const availableCurrencies = Object.keys(CURRENCY_CONVERSIONS)

export const searchBarPlaceholderText = 'Search by products, style code'

export const CART_EXPORT_MODAL_ID = 'cart_export_modal'

export const MULTIPLE_CART_EXPORT_MODAL_ID = 'multiple_cart_export_modal'
